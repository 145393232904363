import { Box, Text } from '@chakra-ui/react'
import { DyteChat } from '@dytesdk/react-ui-kit'
import { useDyteMeeting } from '@dytesdk/react-web-core'
import { Close } from '@mui/icons-material'

function ChatWindow({
  setDisplayChat,
}: {
  setDisplayChat: (displayChat: boolean) => void
}) {
  const { meeting } = useDyteMeeting()

  return (
    <Box
      flex={1}
      ml={{ base: 0, md: 16 }}
      pb={48}
      bgColor="#181818"
      borderRadius={12}
      border="1px solid #1F1F1F"
    >
      <Box p={12} display="flex" justifyContent="space-between">
        <Close
          onClick={() => setDisplayChat(false)}
          style={{ color: '#E3E3E3', cursor: 'pointer' }}
        />
        <Text alignSelf="center" fontSize={14} color="#FFF">
          Chat
        </Text>
        <Box w={24} />
      </Box>
      <DyteChat
        meeting={meeting}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
    </Box>
  )
}

export default ChatWindow
