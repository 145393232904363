import { DyteDialog } from '@dytesdk/react-ui-kit'
import { Box, Image, SimpleGrid, Text } from '@chakra-ui/react'
import BlurOnIcon from '@mui/icons-material/BlurOn'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'

const BACKGROUND_IMAGES = [
  'https://assets.dyte.io/backgrounds/bg-dyte-office.jpg',
  'https://assets.dyte.io/backgrounds/bg_1.jpg',
  'https://assets.dyte.io/backgrounds/bg_2.jpg',
  'https://assets.dyte.io/backgrounds/bg_3.jpg',
  'https://assets.dyte.io/backgrounds/bg_4.jpg',
  'https://assets.dyte.io/backgrounds/bg_5.jpg',
  'https://assets.dyte.io/backgrounds/bg_6.jpg',
  'https://assets.dyte.io/backgrounds/bg_7.jpg',
]

const VirtualBackgroundDialog = ({
  open,
  onClose,
  onSave,
}: {
  open: boolean
  onClose: () => void
  onSave: (mode: string, imgUrl?: string) => void
}) => {
  return (
    <DyteDialog open={open} onDyteDialogClose={onClose}>
      <Box borderRadius={8} w={{ base: 400, sm: 512 }} bg="#000" p={24}>
        <Text variant="h1" fontSize={20} fontWeight={500} color="#FFF">
          Background
        </Text>
        <Box display="flex" my={24}>
          <Box onClick={() => onSave('none')} cursor="pointer" me={24}>
            <DoNotDisturbIcon style={{ width: 64, height: 64 }} />
          </Box>
          <Box onClick={() => onSave('blur')} cursor="pointer">
            <BlurOnIcon style={{ width: 64, height: 64 }} />
          </Box>
        </Box>
        <SimpleGrid columns={4} gap={16}>
          {BACKGROUND_IMAGES.map((imgUrl, i) => (
            <Box
              key={'bgImg:' + i}
              onClick={() => onSave('image', imgUrl)}
              cursor="pointer"
            >
              <Image
                src={imgUrl}
                alt="bg-image"
                style={{ width: 120, height: 120, objectFit: 'cover' }}
              />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </DyteDialog>
  )
}

export default VirtualBackgroundDialog
