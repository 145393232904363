import { useTranslation } from 'react-i18next'
import { ChatListHeader } from './chat-list-header/ChatListHeader'
import {
  ChatListButton,
  ChatListFooter,
  ChatListWrapper,
  ChatUserList,
} from './chat-list.style'
import { Create } from '@mui/icons-material'
import { ChatListUser } from './chat-list-user/ChatListUser'
import { useChatUtils } from '../../../app/providers/chat-utils/useChatUtils'
import { useAuth } from '../../../hooks/useAuth'
import { useState } from 'react'
import { NewChat } from '../../../lib/widgets/new-chat/NewChat'

export function ChatList() {
  const { t } = useTranslation()
  const { chats, chatsLoading } = useChatUtils()
  const { user } = useAuth()
  const [open, setOpen] = useState(false)

  return (
    <ChatListWrapper>
      <ChatListHeader />
      <ChatUserList>
        {chats?.data.map(({ chat, users, unreadMessagesCount }) => {
          const withCoach =
            chat.participants.length === 1 && chat.type !== 'team'
          const partner = users.find((item) => item._id !== user?.user._id)
          const avatar =
            withCoach && chat.coach.profile.picture
              ? chat.coach.profile.picture
              : partner?.profile.picture
          return (
            <ChatListUser
              type={chat.type === 'team' ? 'group' : 'single'}
              key={chat._id}
              withCoach={withCoach}
              id={chat._id}
              avatar={avatar}
              lastMessage={
                chat?.lastMessage ? chat.lastMessage.message.text : ''
              }
              title={
                chat.type === 'team'
                  ? chat.title
                  : withCoach
                    ? chat.coach.profile.name
                    : partner?.profile.name || ''
              }
              unreadMessagesCount={unreadMessagesCount}
            />
          )
        })}
      </ChatUserList>
      <ChatListFooter>
        <ChatListButton onClick={() => setOpen(true)}>
          <Create /> {t('newChat')}
        </ChatListButton>
      </ChatListFooter>
      {open && <NewChat onClose={() => setOpen(false)} />}
    </ChatListWrapper>
  )
}
