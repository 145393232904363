import { Add } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useState } from 'react'
import { useGetTeamMembersQuery } from '../../app/store/services/manager.service'
import { useAuth } from '../../hooks/useAuth'
import { PageTitle } from '../../lib/shared/shared.styles'
import { TeamMembers } from '../../lib/widgets/team-members/TeamMembers'
import { DashboardWrapper } from '../dashboard/dashboard.style'
import { InviteMember } from './InviteMember'

export function TeamMembersPage() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [open, setOpen] = useState(false)

  const { data: teamMembers } = useGetTeamMembersQuery({ includeInvited: '1' })
  return (
    <DashboardWrapper>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            gap: '12px',
          },
        })}
      >
        <div>
          <PageTitle> {t('teamMembers')} </PageTitle>
          {user?.user?.isManager && (
            <p style={{ fontSize: '16px', fontWeight: 300 }}>
              {t('teamMembersPageDescription')}
            </p>
          )}
        </div>
        {user?.user?.isManager && (
          <div style={{ alignSelf: 'flex-end' }}>
            <Button
              onClick={() => setOpen(true)}
              startIcon={<Add />}
              variant="contained"
            >
              {t('inviteTeamMember')}
            </Button>
          </div>
        )}
      </Stack>
      {open && (
        <InviteMember onClose={() => setOpen(false)} member={undefined} />
      )}
      <Stack
        sx={(theme) => ({
          filter: 'var(--card-shadow)',
          padding: '22px',
          background: '#ffffff',
          borderRadius: '12px',
          overflowX: 'auto',
          [theme.breakpoints.down('lg')]: {
            padding: '12px',
          },
        })}
      >
        <TeamMembers members={teamMembers || []} />
      </Stack>
    </DashboardWrapper>
  )
}
