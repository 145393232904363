import { useCallback, useState } from 'react'
import { vsQuestions } from '../../../mock/mockAssessment'
import { VSItem, VSWrapper } from './question-types.style'
import { useAppDispatch, useAppSelector } from '../../../app/store/store.config'
import { setAssessmentResult } from '../../../app/store/slices/uiSlice'

export function VSQuestion({ name }: { name: string }) {
  const results = useAppSelector((store) => store.ui.assessmentResults)

  const dispatch = useAppDispatch()

  const handleClick = useCallback(
    (value: number) => {
      dispatch(
        setAssessmentResult({
          ...results,
          ratings: {
            ...results.ratings,
            [name]: value,
          },
        }),
      )
    },
    [dispatch, name, results],
  )

  return (
    <VSWrapper>
      {vsQuestions.map((item) => (
        <VSItem
          className={item.value === results.ratings[name] ? 'active' : ''}
          onClick={() => handleClick(item.value)}
          key={item.value}
        >
          {item.label}
        </VSItem>
      ))}
    </VSWrapper>
  )
}
