import { useTranslation } from 'react-i18next'
import {
  useCreateChatMutation,
  useGetTeamMembersQuery,
} from '../../../app/store/services/manager.service'
import {
  ListItemFooter,
  NewChatBody,
  NewChatContent,
  NewChatHeader,
  NewChatWrapper,
} from './new-chat.style'
import {
  Avatar,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { generateTwoLetterName } from '../../utils/generateTwoLetterName'
import { stringToColor } from '../../utils/stringToColor'
import { useChatUtils } from '../../../app/providers/chat-utils/useChatUtils'
import { useCallback } from 'react'
import { TeamMember } from '../team-members/TeamMembers'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'

export interface NewChatProps {
  onClose: () => void
}

export function NewChat({ onClose }: NewChatProps) {
  const { data: teamMembers, isFetching: memebersLoading } =
    useGetTeamMembersQuery({})
  const { t } = useTranslation()
  const { chats } = useChatUtils()
  const [createChat, { isLoading: createLoading }] = useCreateChatMutation()
  const navigate = useNavigate()
  const { user } = useAuth()

  const handleContactClick = useCallback(
    async (contact: TeamMember) => {
      if (chats?.data) {
        const foundItem = chats.data.find((item) =>
          item.users.find((elem) => elem._id === contact._id),
        )
        if (foundItem) {
          navigate(`/team-chat/${foundItem.chat._id}`)
          onClose()
        } else {
          const newChat = await createChat({
            memberId: contact._id,
          }).unwrap()
          navigate(`/team-chat/${newChat.data._id}`)
          onClose()
        }
      } else {
        const newChat = await createChat({
          memberId: contact._id,
        }).unwrap()
        console.log(newChat)
        navigate(`/team-chat/${newChat.data._id}`)
        onClose()
      }
    },
    [chats?.data, createChat, navigate, onClose],
  )

  return (
    <NewChatWrapper open={true} onClose={() => onClose()}>
      <DialogTitle>
        <h4> {t('teamMembers')} </h4>
      </DialogTitle>
      <DialogContent sx={{ p: 0, width: '80%' }}>
        {teamMembers
          ?.filter((item) => item.registered && item._id !== user?.user._id)
          .map((item, index) => (
            <ListItemButton
              onClick={() => handleContactClick(item)}
              key={`tm_${index}`}
            >
              <ListItemAvatar>
                <Avatar sx={{ background: stringToColor(item.name) }}>
                  {generateTwoLetterName(item.name)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item.name} secondary={item.email} />
            </ListItemButton>
          ))}
        {(memebersLoading || createLoading) && (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained">
          {t('close')}
        </Button>
      </DialogActions>
    </NewChatWrapper>
  )
}
