import { useSearchParams } from 'react-router-dom'
import {
  NoAuthorWrapper,
  PeerAssessmentContainer,
  PeerAssessmentHeader,
  PeerAssessmentWrapper,
} from './peer-assessment.style'
import Lottie from 'react-lottie'
import animation from '../../assets/lottie/no-data.json'
import { CircularProgress, Stack } from '@mui/material'
import { useGetPeerAssessmentQuery } from '../../app/store/services/core.service'
import { PeerAssessmentUsers } from './PeerAsessmentUsers'
export function PeerAssessmentPage() {
  const [params] = useSearchParams()
  const author = params.get('author')

  const { data, isFetching } = useGetPeerAssessmentQuery(
    { author: author || '' },
    { skip: !author },
  )

  return author ? (
    isFetching ? (
      <NoAuthorWrapper>
        <CircularProgress />
      </NoAuthorWrapper>
    ) : data ? (
      <PeerAssessmentContainer>
        <PeerAssessmentHeader>
          <img src="/logo-full.png" alt="Hupo" />
        </PeerAssessmentHeader>
        <PeerAssessmentWrapper>
          <PeerAssessmentUsers questions={data} />
        </PeerAssessmentWrapper>
      </PeerAssessmentContainer>
    ) : (
      <NoAuthorWrapper></NoAuthorWrapper>
    )
  ) : (
    <NoAuthorWrapper>
      <Stack sx={{ pointerEvents: 'none' }}>
        <Lottie
          options={{
            animationData: animation,
            autoplay: true,
            loop: true,
          }}
          width={200}
        />
      </Stack>
      <div className="info">
        <h1> No author </h1>
        <p>An author must be provided to access this page</p>
      </div>
    </NoAuthorWrapper>
  )
}
