import { Image } from '@chakra-ui/react'

export const Logo = (props: any) => {
  return (
    <a href="https://teamcoach.hupo.co">
      <Image
        src="https://dopmo1eihgbgm.cloudfront.net/646fa927d47205798c223b6d/hupo_logo_.png"
        alt="Hupo logo"
        width="80px"
        {...props}
      />
    </a>
  )
}
