import { styled } from '@mui/material'

export const PeerAssessmentWrapper = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
}))

export const PeerAssessmentHeader = styled('div')(({ theme }) => ({
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
  img: {
    height: '50px',
  },
}))

export const PeerAssessmentContainer = styled('div')(() => ({
  height: '100dvh',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
}))

export const PeerAssessmentUsersList = styled('div')(({ theme }) => ({
  width: '300px',
  borderRight: `1px solid ${theme.palette.divider}`,
}))

export const NoAuthorWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100dvh',
  gap: '24px',
  '.info': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 24px',
    pointerEvents: 'none',
    userSelect: 'none',
    h1: {
      font: '600 18px Poppins, sans-serifs',
      textAlign: 'center',
      pointerEvents: 'none',
    },
    p: {
      font: '400 14px Poppins, sans-serifs',
      textAlign: 'center',
      pointerEvents: 'none',
      color: theme.palette.grey[500],
    },
  },
}))
