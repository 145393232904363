import { useTranslation } from 'react-i18next'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForgotPasswordMutation } from '../../app/store/services/auth.service'
import { toast } from 'sonner'
import { useState } from 'react'
import { HeaderLogo } from '~/layout/main/main-layout.style'
import { usePostHog } from 'posthog-js/react'

export function ForgotPassword() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const posthog = usePostHog()
  const [forgotPassword] = useForgotPasswordMutation()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleForgotPassword = async () => {
    if (!email) {
      setEmailError(t('email_required'))
      return
    }
    setLoading(true)
    try {
      await forgotPassword({ email }).unwrap()
      toast.success(t('password_forgot_success'))
      posthog.capture('password_reset_request_success')
    } catch (error: any) {
      if (error.data.message) {
        toast.error(error.data.message)
      }
      posthog.capture('password_reset_request_fail', {
        message: error?.data?.message,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box height="100%" bgcolor="#fff" pt={2}>
      <HeaderLogo to="/forgot-password">
        <img alt="Hupo" src="/logo-full.png" />
      </HeaderLogo>
      <Box
        display="flex"
        alignItems={'center'}
        justifyContent="center"
        height="80vh"
      >
        <Stack alignItems="center" gap="16px" width="100%" maxWidth="400px">
          <Typography variant="h4" fontWeight={600} color="primary">
            {t('reset_password')}
          </Typography>
          <Typography
            variant="body1"
            color="#555"
            textAlign="center"
            sx={{ mt: -1 }}
          >
            {t('youll_get_an_email_with_a_reset_link')}
          </Typography>
          <TextField
            label="Email"
            color="primary"
            placeholder="Enter your email"
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              setEmailError('')
            }}
            fullWidth
            sx={{ minWidth: 280, mt: 2 }}
            InputLabelProps={{ shrink: true }}
            error={!!emailError}
            helperText={emailError}
          />
          <Button
            variant="contained"
            onClick={handleForgotPassword}
            disabled={loading}
            fullWidth
            size="large"
          >
            {loading ? t('loading') : t('send_reset_link')}
          </Button>
          <Button
            variant="text"
            onClick={() => navigate('/login')}
            fullWidth
            sx={{ height: '40px' }}
          >
            {t('back_to_login')}
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
