export const mockAssessment = [
  {
    optional: false,
    hasOtherText: false,
    options: [],
    _id: '6564f612b9fedb4c6f03c7b7',
    title: 'Start your Hupo Perspectives assessment',
    friendlyID: 'hupo-perspectives-assessment-start',
    description:
      'Gain an understanding of your current strengths, and areas for development by combining feedback from your colleagues with self-reflection. For the most constructive results, make sure you’re in a calm & steady headspace before you begin. Do share openly, you will not be judged based on this exercise, and there are not right or wrong answers. This questionnaire should take no more than 10 minutes.',
    type: 'info',
  },
  {
    optional: false,
    hasOtherText: false,
    options: [
      {
        children: [],
        _id: '656501e9b9fedb4c6f0438d7',
        label: 'Growth mindset towards learning and bold problem solving',
        value: 'growth-mindset-towards-learning-and-bold-problem-solving',
        createdAt: '2023-11-27T20:54:01.152Z',
        updatedAt: '2023-11-27T20:54:01.152Z',
        __v: 0,
      },
      {
        children: [],
        _id: '65650202b9fedb4c6f0438df',
        label:
          "A culture of excellence and raising the bar for 'best-in-class'",
        value: 'culture-of-excellence-and-raising-bar-for-best-in-class',
        createdAt: '2023-11-27T20:54:26.308Z',
        updatedAt: '2023-11-27T20:54:26.308Z',
        __v: 0,
      },
      {
        children: [],
        _id: '6565020cb9fedb4c6f0438e7',
        label: 'Passion, positive enthusiasm',
        value: 'passion-positive-enthusiasm',
        createdAt: '2023-11-27T20:54:36.637Z',
        updatedAt: '2023-11-27T20:54:36.637Z',
        __v: 0,
      },
      {
        children: [],
        _id: '65650223b9fedb4c6f0438ef',
        label: 'Ownership, accountability and determination that drives impact',
        value: 'ownership-accountability-determination-drives-impact',
        createdAt: '2023-11-27T20:54:59.739Z',
        updatedAt: '2023-11-27T20:54:59.739Z',
        __v: 0,
      },
      {
        children: [],
        _id: '65650238b9fedb4c6f0438f7',
        label:
          'Authenticity, empathy, kindness towards each other and our community',
        value: 'authenticity-empathy-kindness-towards-each-other-our-community',
        createdAt: '2023-11-27T20:55:20.695Z',
        updatedAt: '2023-11-27T20:55:20.695Z',
        __v: 0,
      },
    ],
    _id: '6564f62cb9fedb4c6f03c7bc',
    title: 'Organisational Leadership Values',
    friendlyID: 'organisational-leadership-values',
    description:
      'Your organisation has selected the following core leadership values to guide your coaching journey.',
    type: 'list',
  },
  {
    optional: false,
    hasOtherText: false,
    options: [],
    _id: '6564f65cb9fedb4c6f03c7c0',
    friendlyID: 'value-score-1',
    title: 'How would you rate yourself on',
    type: 'value-score',
  },
  {
    optional: false,
    hasOtherText: false,
    options: [],
    _id: '6564f665b9fedb4c6f03c7c4',
    title: 'How would you rate yourself on',
    friendlyID: 'value-score-2',
    type: 'value-score',
  },
  {
    optional: false,
    hasOtherText: false,
    options: [],
    _id: '6564f66fb9fedb4c6f03c7c8',
    title: 'How would you rate yourself on',
    friendlyID: 'value-score-3',
    type: 'value-score',
  },
  {
    optional: false,
    hasOtherText: false,
    options: [],
    _id: '6564f677b9fedb4c6f03c7cc',
    title: 'How would you rate yourself on',
    friendlyID: 'value-score-4',
    type: 'value-score',
  },
  {
    optional: false,
    hasOtherText: false,
    options: [],
    _id: '6564f67fb9fedb4c6f03c7d0',
    title: 'How would you rate yourself on',
    friendlyID: 'value-score-5',
    type: 'value-score',
  },
  {
    optional: false,
    hasOtherText: false,
    options: [],
    _id: '6564f746b9fedb4c6f03c819',
    title: 'Next, we will ask you some open ended questions',
    friendlyID: 'open-ended-section',
    description:
      'Your responses to these next few prompts will provide your coach with valuable context to guide your coaching journey. Please take time to be thoughtful and specific.\\n\\nFor each prompt, use 2-4 sentences to explain your behaviour and/or impact you see resulting from your actions.',
    type: 'info',
  },
  {
    optional: false,
    hasOtherText: false,
    options: [],
    _id: '6564f767b9fedb4c6f03c81d',
    title: 'What do you see as your strengths?',
    friendlyID: 'what-do-you-see-as-your-strength',
    description:
      '(i.e. What do you feel you do particularly well and should continue to do?)',
    type: 'textarea',
  },
  {
    optional: false,
    hasOtherText: false,
    options: [],
    _id: '6564f786b9fedb4c6f03c821',
    title: 'What do you think you could develop further?',
    friendlyID: 'what-do-you-think-you-could-develop-further',
    description: '(i.e. What should you do more of?)',
    type: 'textarea',
    placeholder:
      'Please share some specific examples of what improvements in these areas might look like',
  },
  {
    optional: false,
    hasOtherText: false,
    options: [],
    _id: '6564f79fb9fedb4c6f03c825',
    title:
      'What are some obstacles (if any) standing in the way of you performing as your best self?',
    friendlyID: 'obstacles-standing-in-way-to-perform-best',
    type: 'textarea',
  },
  {
    optional: false,
    hasOtherText: false,
    options: [],
    _id: '6564f7fab9fedb4c6f03d3a2',
    title: 'Thank you!',
    friendlyID: 'assessment-completion-confirmation-insert-peers',
    description:
      'Your complete Hupo Peer Perspectives report will be ready by your next coaching session. We’ve sent an invitation to the following contributors: \\n\\nmuraziz@gmail.com\\naziz+recipient@hupo.co\\nealderson213@gmail.com\\nmuraziz.com\\naziz+testmanagerorpeer@hupo.co\\naziz+testmanagerpeer2@hupo.co\\naziz+testmanagerorpeer3@hupo.co\\naziz@getami.co\\n\\nYour colleagues have 7 days to complete their portion of this review. In the meantime, book your coach for the first session!',
    type: 'info',
  },
]

export const valueScores = [
  {
    question: 'Growth mindset towards learning and bold problem solving?',
    friendlyID: 'value-score-1',
  },
  {
    question:
      "A culture of excellence and raising the bar for 'best-in-class'?",
    friendlyID: 'value-score-2',
  },
  {
    question: 'Passion, positive enthusiasm?',
    friendlyID: 'value-score-3',
  },
  {
    question: 'Ownership, accountability and determination that drives impact?',
    friendlyID: 'value-score-4',
  },
  {
    question:
      'Authenticity, empathy, kindness towards each other and our community?',
    friendlyID: 'value-score-5',
  },
  {
    question:
      'Authenticity, empathy, kindness towards each other and our community?',
    friendlyID: 'value-score-5',
  },
]

export type QuestionItem = {
  _id: string
  label: string
  value: string
  createdAt: string
  updatedAt: string
  __v: number
}

export const vsQuestions = [
  {
    label: 'Strongly Disagree',
    value: 1,
  },
  {
    label: 'Disagree',
    value: 2,
  },
  {
    label: 'Neutral',
    value: 3,
  },
  {
    label: 'Agree',
    value: 4,
  },
  {
    label: 'Strongly Agree',
    value: 5,
  },
]
