import { AnimatePresence, motion } from 'framer-motion'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { usePostAssessmentQuestionsMutation } from '../../app/store/services/manager.service'
import { useAppSelector } from '../../app/store/store.config'
import { AssessmentProgress } from './assessment-progress/AssessmentProgress'
import { AssessmentQuestion } from './assessment-question/AssessmentQuestion'
import { AssessmentItemWrapper } from './assessment.style'
import { ListQuestion } from './question-types/ListQuestion'
import { TextAreaQuestion } from './question-types/TextAreaQuestion'
import { VSQuestion } from './question-types/VSQuestion'

export function AssessmentItem() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const questions = useAppSelector((store) => store.ui.assessment)
  const answers = useAppSelector((store) => store.ui.assessmentResults)
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const [, , index] = friendlyId ? friendlyId.split('-') : ([0, 0, 1] as any)
  const [postAssessments, { isLoading }] = usePostAssessmentQuestionsMutation()

  const currentValue = questions.values[index - 1]

  const currentQuestion = questions.questions.find(
    (item) => item.friendlyID === friendlyId,
  )!

  const currentIndex = questions.questions.findIndex(
    (item) => item.friendlyID === friendlyId,
  )!

  const progress =
    ((currentIndex === 0 ? 1 : currentIndex + 1) / questions.questions.length) *
    100

  const handleNext = useCallback(async () => {
    if (currentIndex <= questions.questions.length - 2) {
      // validations
      if (
        currentQuestion.type === 'value-score' &&
        !answers.ratings[currentValue?.value] &&
        !currentQuestion.optional
      ) {
        toast.error(t('pleaseSelectValue'))
        return
      }
      if (
        currentQuestion.type === 'textarea' &&
        (!answers.answers[currentQuestion?.friendlyID] ||
          answers.answers[currentQuestion?.friendlyID].length === 0) &&
        !currentQuestion.optional
      ) {
        toast.error(t('pleaseEnterValue'))
        return
      }
      if (
        currentQuestion.type === 'textarea' &&
        !currentQuestion.optional &&
        currentQuestion.minWordLimit &&
        !satisfiesWordCount(
          answers.answers[currentQuestion.friendlyID],
          currentQuestion.minWordLimit,
        )
      ) {
        toast.error(
          t('pleaseEnterAtLeastWords', {
            wordCount: currentQuestion.minWordLimit,
          }),
        )
        return false
      }

      if (currentIndex === questions.questions.length - 2) {
        // if last (non-thanks) question, submit
        const listResults: any = {}
        questions.questions
          .filter((item) => item.type === 'list')
          .forEach((item: any) => {
            listResults[item.friendlyID] = []
          })
        const backdata = {
          ...answers,
          answers: {
            ...answers.answers,
            ...listResults,
          },
        }
        await postAssessments(backdata).unwrap()
        navigate(
          `/assessment/${questions.questions[currentIndex + 1].friendlyID}`,
        )
      } else {
        // otherwise, just move to next one
        navigate(
          `/assessment/${questions.questions[currentIndex + 1].friendlyID}`,
        )
      }
    } else {
      navigate('/')
    }
  }, [
    answers,
    currentIndex,
    currentQuestion,
    currentValue?.value,
    navigate,
    postAssessments,
    questions.questions,
    t,
  ])

  const handleBack = useCallback(() => {
    if (currentIndex === 0) {
      navigate('/')
    } else {
      navigate(
        `/assessment/${questions.questions[currentIndex - 1].friendlyID}`,
      )
    }
  }, [currentIndex, navigate, questions.questions])

  return (
    <AssessmentItemWrapper>
      <AssessmentProgress onBack={handleBack} percent={progress} />
      <AssessmentQuestion
        progress={progress}
        onButtonClick={handleNext}
        title={currentQuestion?.title}
        subTitle={
          currentValue ? currentValue.description : currentQuestion.description
        }
        isLoading={isLoading}
        isSubtitleQuestion={currentQuestion.type === 'value-score'}
        buttonText={
          currentIndex === 0
            ? 'Start'
            : currentIndex === questions.questions.length - 1
              ? 'Finish'
              : 'Continue'
        }
      >
        <AnimatePresence key={currentQuestion.friendlyID}>
          <motion.div
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {currentQuestion.type === 'info' ? (
              <div />
            ) : currentQuestion.type === 'textarea' ? (
              <TextAreaQuestion
                name={currentQuestion.friendlyID}
                placeholder={currentQuestion.description || ''}
                minWordLimit={currentQuestion.minWordLimit}
              />
            ) : currentQuestion.type === 'list' ? (
              <ListQuestion questions={currentQuestion.options} />
            ) : (
              <VSQuestion name={currentValue.value} />
            )}
          </motion.div>
        </AnimatePresence>
      </AssessmentQuestion>
    </AssessmentItemWrapper>
  )
}

const satisfiesWordCount = (text: string, minWordLimit: number) => {
  if (!text) return false
  // calculate word count of text
  const words = text.split(/\s+/)
  return words.length >= minWordLimit
}
