import { DyteEmojiPicker } from '@dytesdk/react-ui-kit'
import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core'
import DyteVideoBackgroundTransformer from '@dytesdk/video-background-transformer'
import { useEffect, useState } from 'react'
import { HiOutlineEmojiHappy } from 'react-icons/hi'

import { Box, useMediaQuery } from '@chakra-ui/react'
import { Close } from '@mui/icons-material'
import { Badge, Modal } from '@mui/material'
import clsx from 'clsx'
import { MessageSquare, Mic, MicOff, Video, VideoOff, X } from 'react-feather'
import { TbScreenShare, TbScreenShareOff } from 'react-icons/tb'
import VirtualBackgroundDialog from '../VirtualBackgroundDialog'

export const Controlbar = ({
  disableShareButton,
  screenShareEnabled,
  setDisplayChat,
  displayChat,
}: {
  disableShareButton: boolean
  screenShareEnabled: boolean
  setDisplayChat: (displayChat: boolean) => void
  displayChat: boolean
}) => {
  const { meeting } = useDyteMeeting()

  const participants = useDyteSelector((m) => m.participants.joined)

  const [showBgDialog, setShowBgDialog] = useState<boolean>(false)
  const [dyteVideoMiddleware, setDyteVideoMiddleWare] = useState<any>(null)

  const { videoEnabled, audioEnabled } = useDyteSelector((m) => ({
    videoEnabled: m.self.videoEnabled,
    audioEnabled: m.self.audioEnabled,
  }))

  const [userMessageMap, setUserMessageMap] = useState<any>({})
  const [newMessageCount, setNewMessageCount] = useState<number>(0)
  const [displayEmojiPicker, setDisplayEmojiPicker] = useState(false)

  useEffect(() => {
    if (meeting.chat.messages.length > 0) {
      const messageMap = { ...userMessageMap }
      for (const msg of meeting.chat.messages) {
        if (
          msg.userId !== meeting.self.userId &&
          (msg.targetUserIds?.length === 0 ||
            msg.targetUserIds?.find((id) => id === meeting.self.userId))
        ) {
          if (!(msg.id in messageMap) && !displayChat) {
            setNewMessageCount((count) => count + 1)
          }
          messageMap[msg.id] = msg.userId
        }
      }
      setUserMessageMap(messageMap)
    }
  }, [meeting.chat.messages.length])

  const toggleCamera = () => {
    if (meeting.self.videoEnabled) {
      meeting.self.disableVideo()
    } else {
      meeting.self.enableVideo()
    }
  }

  const toggleMic = () => {
    if (meeting.self.audioEnabled) {
      meeting.self.disableAudio()
    } else {
      meeting.self.enableAudio()
    }
  }

  const toggleScreenShare = async () => {
    if (screenShareEnabled) {
      await meeting.self.disableScreenShare()
    } else {
      await meeting.self.enableScreenShare()
    }
  }

  const toggleChat = () => {
    setNewMessageCount(0)
    setDisplayChat(!displayChat)
  }

  const leaveMeeting = () => {
    meeting.leaveRoom()
  }

  const setVirtualBackground = async (mode: string, imgUrl?: string) => {
    if (DyteVideoBackgroundTransformer.isSupported()) {
      if (dyteVideoMiddleware) {
        meeting.self.removeVideoMiddleware(dyteVideoMiddleware)
        setDyteVideoMiddleWare(null)
      }
      if (mode !== 'none') {
        const videoBackgroundTransformer =
          await DyteVideoBackgroundTransformer.init()

        const videoMiddleware =
          mode === 'blur'
            ? await videoBackgroundTransformer.createBackgroundBlurVideoMiddleware(
                15,
              )
            : imgUrl
              ? await videoBackgroundTransformer.createStaticBackgroundVideoMiddleware(
                  imgUrl,
                )
              : null
        if (videoMiddleware) {
          meeting.self.addVideoMiddleware(videoMiddleware)
          setDyteVideoMiddleWare(videoMiddleware)
        }
      }
      setShowBgDialog(false)
    }
  }

  const handleEmojiPick = (emoji: any) => {
    setDisplayEmojiPicker(false)
    meeting.participants.broadcastMessage('emoji', {
      emojiType: emoji.detail,
      self: meeting.self,
    } as any)
  }

  const isMobileScreen = useMediaQuery('(max-width: 640px)')[0]

  return (
    <div className="z-20 flex w-full max-w-md sm:w-min pt-4 justify-center">
      <div className="w-full p-4 gap-4 bg-neutral-800 shadow-2xl shadow-black rounded-2xl md:rounded-3xl flex flex-col">
        <div className="text-sm">
          <h1>{meeting.meta.meetingTitle}</h1>
          <div className="text-stone-400">
            {participants.size + 1} People Active
          </div>
        </div>
        <div className="flex items-center justify-evenly gap-3">
          <button
            className={clsx(
              'p-3 flex items-center justify-center rounded-full',
              audioEnabled
                ? 'bg-white text-black'
                : 'bg-neutral-700 text-red-500',
            )}
            onClick={toggleMic}
          >
            {audioEnabled ? <Mic /> : <MicOff />}
          </button>
          <button
            className={clsx(
              'p-3 flex items-center justify-center rounded-full',
              videoEnabled
                ? 'bg-white text-black'
                : 'bg-neutral-700 text-red-500',
            )}
            onClick={toggleCamera}
          >
            {videoEnabled ? <Video /> : <VideoOff />}
          </button>
          {/* {isHupoInternal(user?.user.email) ? (
            <button
              className={clsx(
                "p-3 flex items-center justify-center rounded-full",
                dyteVideoMiddleware
                  ? "bg-white text-black"
                  : "bg-neutral-700 text-white"
              )}
              onClick={() => setShowBgDialog(true)}
            >
              <AutoAwesomeIcon />
            </button>
          ) : null} */}
          <button
            className={clsx(
              'p-3 flex items-center justify-center rounded-full',
              displayChat ? 'bg-white text-black' : 'bg-neutral-700 text-white',
            )}
            onClick={toggleChat}
          >
            <Badge badgeContent={newMessageCount} color="error">
              <MessageSquare size={24} />
            </Badge>
          </button>
          <button
            className={clsx(
              'p-2.5 flex items-center justify-center rounded-full',
              displayEmojiPicker
                ? 'bg-white text-black'
                : 'bg-neutral-700 text-white',
            )}
            onClick={() => setDisplayEmojiPicker(!displayEmojiPicker)}
          >
            <HiOutlineEmojiHappy size={28} />
          </button>
          {!isMobileScreen ? (
            <button
              className={clsx(
                'p-3 flex items-center justify-center rounded-full',
                screenShareEnabled
                  ? 'bg-white text-black'
                  : 'bg-neutral-700 text-white',
                !screenShareEnabled && disableShareButton
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer',
              )}
              onClick={toggleScreenShare}
              disabled={disableShareButton}
            >
              {screenShareEnabled ? (
                <TbScreenShareOff size={24} />
              ) : (
                <TbScreenShare size={24} />
              )}
            </button>
          ) : null}
          <button
            className="bg-red-500 text-white p-3 flex items-center justify-center rounded-full"
            onClick={leaveMeeting}
          >
            <X />
          </button>
        </div>
      </div>
      <VirtualBackgroundDialog
        open={showBgDialog}
        onClose={() => setShowBgDialog(false)}
        onSave={setVirtualBackground}
      />
      <Modal
        open={displayEmojiPicker}
        onClose={() => setDisplayEmojiPicker(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '20px',
        }}
      >
        <Box overflow="hidden" pt={4} bgColor="#1E1E1E">
          <Box
            position="relative"
            right={4}
            top={4}
            display="flex"
            justifyContent="right"
            mb="-24px"
            style={{ cursor: 'pointer' }}
          >
            <Close
              sx={{ color: '#FFF' }}
              onClick={() => setDisplayEmojiPicker(false)}
            />
          </Box>
          <Box
            style={{
              width: 400,
            }}
          >
            <DyteEmojiPicker onDyteEmojiClicked={(e) => handleEmojiPick(e)} />
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
