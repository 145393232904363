import { Box, List, ListItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useGetAssessmentAnswersQuery } from '../../app/store/services/manager.service'
import { DashboardCardTitle } from '../../lib/shared/dashboard-card/dashboard-card.style'

export function OpenEndedAnswers() {
  const { t } = useTranslation()
  const { data: assessmentAnswers, isLoading } = useGetAssessmentAnswersQuery()

  if (isLoading) {
    return <div>{t('loading')}</div>
  }

  return (
    <Box
      sx={{
        filter: 'var(--card-shadow)',
        padding: '22px',
        background: '#ffffff',
        borderRadius: '12px',
      }}
    >
      <DashboardCardTitle style={{ marginBottom: 16 }}>
        {t('openEndedAnswers')}
      </DashboardCardTitle>
      {assessmentAnswers &&
        Object.entries(assessmentAnswers).map(([questionId, answers]: any) => (
          <Box key={questionId} mb={2}>
            <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
              {answers[0].question.title}
            </Typography>
            <List sx={{ listStyleType: 'disc' }}>
              {answers.map((answer: any, index: number) => (
                <ListItem
                  key={`${questionId}_${index}`}
                  sx={{ display: 'list-item', marginLeft: 3 }}
                >
                  {answer.otherAnswer}
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
    </Box>
  )
}
