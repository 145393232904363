import {
  DyteAudioVisualizer,
  DyteAvatar,
  DyteClock,
  DyteLogo,
  DyteNameTag,
  DyteParticipantsAudio,
  DyteParticipantTile,
  DyteRecordingIndicator,
  provideDyteDesignSystem,
} from '@dytesdk/react-ui-kit'
import { useDyteSelector } from '@dytesdk/react-web-core'
import { useEffect, useState } from 'react'

import { Box, useMediaQuery } from '@chakra-ui/react'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import ChatWindow from './ChatWindow'
import PostSessionUserFeedback from '../../../feedback/PostSessionUserFeedback'
import ScreenShareView from './ScreenShareView'
import { AlternativeLinkFromMenuOneOnOne } from './oneononecall/AlternativeLinkFromMenuOneOnOne'
import { Controlbar } from './oneononecall/Controlbar'
import { Grid } from './oneononecall/Grid'

// Taken from Facetime sample
// https://github.com/dyte-io/react-samples/tree/main/samples/facetime
export default function Facetime({
  meeting,
  isCoach,
  sessionId,
  userId,
  coachName,
}: any) {
  const roomJoined = useDyteSelector((m) => m.self.roomJoined)

  const { screenShareEnabled } = useDyteSelector((m) => ({
    screenShareEnabled: m.self.screenShareEnabled,
  }))
  const participants = useDyteSelector((m) => m.participants.joined)
  const isMobileScreen = useMediaQuery('(max-width: 640px)')[0]
  const { t } = useTranslation()

  const [displayChat, setDisplayChat] = useState(false)
  const [isMeetingEnded, setIsMeetingEnded] = useState(false)

  useEffect(() => {
    provideDyteDesignSystem(document.body, {
      colors: {
        'video-bg': '#333333',
      },
    })
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      toast.message(t('ai_transcription_in_progress'), {
        duration: 5000,
        position: 'bottom-center',
      })
    }, 3000) // 3 seconds
    return () => clearTimeout(timer)
  }, [t])

  const handleMeetingEnded = () => {
    setIsMeetingEnded(true)
  }

  useEffect(() => {
    if (meeting) {
      meeting.self.on('roomLeft', handleMeetingEnded)
    }
    return () => {
      if (meeting) {
        meeting.self.on('roomLeft', handleMeetingEnded)
      }
    }
  }, [meeting])

  const ssParticipant: any = [
    ...meeting.participants.joined.toArray(),
    meeting.self,
  ].find((p: any) => p.screenShareEnabled)

  const displayScreenShareView: any = ssParticipant ? true : false
  const disableShareButton =
    ssParticipant && ssParticipant?.id !== meeting.self.id

  if (!roomJoined && !isCoach && isMeetingEnded) {
    return (
      <PostSessionUserFeedback
        sessionId={sessionId}
        userId={userId}
        coachName={coachName}
        source={'post_call'}
      />
    )
  }

  if (!roomJoined) {
    return (
      <div className="bg-black text-white w-full h-full flex place-items-center justify-center">
        <p className="text-2xl">You are not in the meeting.</p>
      </div>
    )
  }

  console.log('meeting.self:', meeting.self)

  return (
    <div className="h-full w-full p-4 flex flex-col bg-black text-white overflow-hidden">
      <DyteParticipantsAudio meeting={meeting} />
      <div className="absolute top-4 left-4">
        {participants.size > 0 ? (
          <AlternativeLinkFromMenuOneOnOne
            sessionId={sessionId}
            participantId={meeting.self.id}
            isCoach={isCoach}
          />
        ) : null}
        <DyteRecordingIndicator meeting={meeting} />
      </div>
      <div className="absolute top-4 right-4">
        <DyteClock meeting={meeting} />
      </div>
      <div className="pb-4 pt-2 flex items-center justify-center">
        <DyteLogo logoUrl="/logo-full.png" style={{ height: '30px' }} />
      </div>
      <Box textAlign="center">
        {displayScreenShareView && participants.size === 0 && (
          <p className="text-2xl">No one else is here</p>
        )}
      </Box>
      <Box display="flex" flex={1}>
        <Box
          display={isMobileScreen && displayChat ? 'none' : 'flex'}
          flex={4}
          mr={16}
        >
          {displayScreenShareView ? (
            <ScreenShareView participant={ssParticipant} />
          ) : null}

          <Grid
            meeting={meeting}
            displayScreenShareView={displayScreenShareView}
          />
        </Box>

        {displayChat ? <ChatWindow setDisplayChat={setDisplayChat} /> : null}
      </Box>
      {!displayScreenShareView &&
      (!isMobileScreen || (isMobileScreen && !displayChat)) ? (
        <Draggable bounds="parent">
          <DyteParticipantTile
            participant={meeting.self}
            key={meeting.self.id}
            meeting={meeting}
            className="z-10 absolute bottom-44 right-4 sm:bottom-4 shadow-black shadow-2xl aspect-square w-28 sm:w-40 md:w-52 h-auto cursor-move duration-0"
          >
            <DyteAvatar
              participant={meeting.self}
              size={isMobileScreen ? 'sm' : 'md'}
            />
            <DyteNameTag participant={meeting.self} size="md">
              <DyteAudioVisualizer
                participant={meeting.self}
                size="md"
                slot="start"
              />
            </DyteNameTag>
          </DyteParticipantTile>
        </Draggable>
      ) : null}
      <Controlbar
        disableShareButton={disableShareButton}
        screenShareEnabled={screenShareEnabled}
        setDisplayChat={setDisplayChat}
        displayChat={displayChat}
      />
    </div>
  )
}
