import { Box, Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useMemo } from 'react'
import { useGetAssessmentStatusQuery } from '../../app/store/services/manager.service'
import { useAuth } from '../../hooks/useAuth'
import { PageTitle } from '../../lib/shared/shared.styles'
import { SessionStats } from '../dashboard/session-stats/SessionStats'
import { DiagnosticsRequired } from './DiagnosticsRequired'
import { HighlightedAreas } from './HighlightedAreas'
import { LeadershipValueChart } from './LeadershipValueChart'
import { MoreDiagnosticsAwaiting } from './MoreDiagnosticsAwaiting'
import { OpenEndedAnswers } from './OpenEndedAnswers'
import { ScrollDownIndicator } from './ScrollDownIndicator'
import { TeamProgressStatus } from './TeamProgressStatus'
import { ProgressWrapper } from './progress.style'

export function ProgressPage() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { data: assessmentStatusData, isLoading } =
    useGetAssessmentStatusQuery()

  const assessmentStatus = useMemo(
    () => ({
      selfAssessmentCompleted: assessmentStatusData?.status === 'COMPLETED',
      canShowTeamResults: assessmentStatusData?.canShowTeamResults,
      completedCount: assessmentStatusData?.completedCount ?? 0,
      totalCount: assessmentStatusData?.totalCount ?? 0,
      minRequiredCount: assessmentStatusData?.minRequiredCount ?? 0,
    }),
    [assessmentStatusData],
  )

  return (
    <ProgressWrapper>
      <PageTitle> {t('progress')} </PageTitle>
      <Box py={2} gap={2} display={'grid'}>
        <SessionStats />
        {assessmentStatus.selfAssessmentCompleted &&
        assessmentStatus.canShowTeamResults ? (
          <ScrollDownIndicator />
        ) : null}
      </Box>
      {isLoading || assessmentStatus.selfAssessmentCompleted ? null : (
        <Stack pb={2}>
          <DiagnosticsRequired />
        </Stack>
      )}
      {user?.user.isManager ? (
        <Stack
          sx={{
            filter: 'var(--card-shadow)',
            padding: '22px',
            background: '#ffffff',
            borderRadius: '12px',
            marginBottom: 2,
          }}
        >
          <TeamProgressStatus />
        </Stack>
      ) : null}
      {assessmentStatus.selfAssessmentCompleted ? (
        assessmentStatus.canShowTeamResults ? (
          <>
            <Box pb={2}>
              <LeadershipValueChart />
            </Box>
            <Grid container spacing={2}>
              <Grid item width={['100%', '100%', '50%']}>
                <HighlightedAreas top={true} />
              </Grid>
              <Grid item width={['100%', '100%', '50%']}>
                <HighlightedAreas top={false} />
              </Grid>
            </Grid>
            <Box py={2}>
              <OpenEndedAnswers />
            </Box>
          </>
        ) : (
          <Stack
            sx={{
              filter: 'var(--card-shadow)',
              padding: '22px',
              background: '#ffffff',
              borderRadius: '12px',
              marginBottom: 2,
            }}
          >
            <MoreDiagnosticsAwaiting
              minRequiredCount={assessmentStatus.minRequiredCount}
              completedCount={assessmentStatus.completedCount}
              totalCount={assessmentStatus.totalCount}
            />
          </Stack>
        )
      ) : null}
    </ProgressWrapper>
  )
}
