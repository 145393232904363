import { useTranslation } from 'react-i18next'

import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useGetAssessmentValueScoresQuery } from '../../app/store/services/manager.service'
import { DashboardCard } from '../../lib/shared/dashboard-card/DashboardCard'
import { LeadershipValueTable } from './progress.style'

const legend = [
  { name: 'Strongly disagree' },
  { name: 'Disagree' },
  { name: 'Neutral' },
  { name: 'Agree' },
  { name: 'Strongly agree' },
]

const splitText = (text: string) => {
  const words = text.split(' ')
  const result = []
  let line: any = []
  words.forEach((word) => {
    if (line.join(' ').length + word.length > 18) {
      result.push(line.join(' '))
      line = []
    }
    line.push(word)
  })
  result.push(line.join(' '))
  return result
}

export function LeadershipValueChart() {
  const { t } = useTranslation()
  const [series, setSeries] = useState<any>([])
  const [options, setOptions] = useState<any>({})
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('md'))

  const { data: assessmentValues } = useGetAssessmentValueScoresQuery()

  useEffect(() => {
    if (!assessmentValues) {
      return
    }

    const selfScore: any = []
    const teamScore: any = []

    if (assessmentValues.scores.team) {
      Object.keys(assessmentValues.scores.team).forEach((key) => {
        teamScore.push(assessmentValues.scores.team[key])
      })
    }
    if (assessmentValues.scores.self) {
      Object.keys(assessmentValues.scores.self).forEach((key) => {
        selfScore.push(assessmentValues.scores.self[key])
      })
    }

    const series = [
      {
        name: 'Self',
        data: selfScore,
      },
      {
        name: 'Team (average)',
        data: teamScore,
      },
    ]
    setSeries(series)

    const options = {
      chart: {
        height: 450,
        type: 'radar',
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
        toolbar: {
          show: true,
        },
      },
      colors: ['#aaaadd', 'tomato'],
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: Object.keys(assessmentValues.values).map((key: any) =>
          splitText(assessmentValues.values[key]),
        ),
      },
    }
    setOptions(options)
  }, [assessmentValues])
  return (
    <DashboardCard title={t('teamDNA')}>
      {!isSm ? (
        <>
          <ReactApexChart
            options={options}
            series={series}
            type="radar"
            height={900}
          />
          <DashboardCard title="Rating scale for Team Diagnostics">
            <ol className="ml-4">
              {legend.map((item, index) => (
                <li className="flex items-center gap-2" key={item.name}>
                  <div className="w-4 h-4 rounded-full bg-gray-900/10 flex items-center justify-center">
                    <span className="text-xs font-semibold text-gray-900">
                      {index + 1}
                    </span>
                  </div>
                  {item.name}
                </li>
              ))}
            </ol>
          </DashboardCard>
        </>
      ) : (
        <LeadershipValueTable>
          <thead>
            <tr>
              <th></th>
              <th> Self </th>
              <th> Team (average) </th>
            </tr>
          </thead>
          <tbody>
            {assessmentValues &&
              Object.keys(assessmentValues.values).map((key) => (
                <tr key={key}>
                  <td> {assessmentValues.values[key]} </td>
                  <td> {assessmentValues.scores.self[key].toFixed(1)} </td>
                  <td> {assessmentValues.scores.team[key].toFixed(1)} </td>
                </tr>
              ))}
          </tbody>
        </LeadershipValueTable>
      )}
    </DashboardCard>
  )
}
