import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envVars } from '../../../constants/envVars'
import { RootState } from '../store.config'

export const calendarService = createApi({
  reducerPath: 'calendarApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envVars.BASE_URL,
    prepareHeaders: (headers, api) => {
      const store = api.getState() as RootState
      if (store.auth.user) {
        headers.set('Authorization', `Bearer ${store.auth.user.token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getCalendars: builder.query({
      query: () => ({
        url: '/user/external_calendars',
        method: 'GET',
      }),
    }),
    patchExternalCalendar: builder.mutation({
      query: ({ calendarId, ...body }) => ({
        url: `/user/external_calendars/${calendarId}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteExternalCalendar: builder.mutation({
      query: (id) => ({
        url: `/user/external_calendars/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetCalendarsQuery,
  usePatchExternalCalendarMutation,
  useDeleteExternalCalendarMutation,
} = calendarService
