import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CompletedSessionsIcon from '../../../assets/icons/CompletedSessionsIcon'
import LeftSessionsIcon from '../../../assets/icons/LeftSessionsIcon'
import ScheduledSessionsIcon from '../../../assets/icons/ScheduledSessionsIcon'
import { DashboardCard } from '../../../lib/shared/dashboard-card/DashboardCard'
import { DashboardCardLink } from '../../../lib/shared/dashboard-card/dashboard-card.style'
import { SessionStatItem, SessionStatsWrapper } from './session-stats.style'

export function SessionStatsContent({
  showViewMore,
  mode,
  sessionData,
}: {
  showViewMore: boolean
  mode: 'individual' | 'team' | 'all'
  sessionData: {
    upcomingCount: number
    completedCount: number
    remainingCount: number
  }
}) {
  const { t } = useTranslation()
  let title = t('sessionStats')
  if (mode === 'individual') {
    title = t('individualSessionStats')
  } else if (mode === 'team') {
    title = t('teamSessionStats')
  }
  return (
    <Stack gap="12px">
      <DashboardCard title={title}>
        <SessionStatsWrapper>
          <SessionStatItem>
            <ScheduledSessionsIcon />
            <div className="statContent">
              <p> {t('upcomingSessions')} </p>
              <h2> {sessionData.upcomingCount} </h2>
            </div>
          </SessionStatItem>
          <SessionStatItem>
            <CompletedSessionsIcon />
            <div className="statContent">
              <p> {t('completedSessions')} </p>
              <h2>{sessionData?.completedCount} </h2>
            </div>
          </SessionStatItem>
          <SessionStatItem>
            <LeftSessionsIcon />
            <div className="statContent">
              <p> {t('leftSessions')} </p>
              <h2> {sessionData?.remainingCount} </h2>
            </div>
          </SessionStatItem>
        </SessionStatsWrapper>
        {showViewMore && (
          <DashboardCardLink to="/progress">
            {t('viewAllStats')}
          </DashboardCardLink>
        )}
      </DashboardCard>
    </Stack>
  )
}
