import { Box, Modal, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useGetAssessmentStatusQuery,
  useGetSessionsQuery,
} from '../../app/store/services/manager.service'
import { PageTitle } from '../../lib/shared/shared.styles'
import { CoachDialog } from '../../lib/widgets/coach-dialog/CoachDialog'
import { DashboardCoaches } from './coaches/DashboardCoaches'
import {
  DashboardContent,
  DashboardLeft,
  DashboardRight,
  DashboardWrapper,
} from './dashboard.style'
import { SessionStats } from './session-stats/SessionStats'
import { UpcomingSessions } from './upcoming-sessions/UpcomingSessions'
import { DashboardTeamMembers } from './team-members/DashboardTeamMembers'
import { AssessmentCTA } from './assessment/AssessmentCTA'
import PostSessionUserFeedback from '../feedback/PostSessionUserFeedback'
import { Close } from '@mui/icons-material'
import { useAuth } from '../../hooks/useAuth'
import {
  useGetLastSessionFeedbackQuery,
  useSkipFeedbackMutation,
} from '../../app/store/services/feedback.service'
import { NpsSurvey } from '../feedback/NpsSurvey'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '~/app/store/store.config'
import { useGetNpsSurveyQuery } from '~/app/store/services/nps.service'
import { setNpsSurveyQuestions } from '~/app/store/slices/npsSlice'

export function Dashboard() {
  const { t } = useTranslation()
  const [openPostSessionFeedback, setOpenPostSessionFeedback] = useState(false)
  const { user } = useAuth()
  const dispatch = useAppDispatch()

  const date = useMemo(
    () => ({
      start: moment(),
      end: moment().add(3, 'days'),
    }),
    [],
  )
  // Pre-fetch sessions
  const { data: _ } = useGetSessionsQuery(
    {
      start: date.start.toISOString(),
      end: date.end.toISOString(),
    },
    { refetchOnMountOrArgChange: true },
  )

  const { data: assessmentStatusData } = useGetAssessmentStatusQuery()

  const { data: lastSessionFeedback } = useGetLastSessionFeedbackQuery(
    user?.user?._id,
  )

  const { shouldAskNps, sessionId: surveySessionId } = useAppSelector(
    (store: RootState) => store.nps,
  )

  const { data } = useGetNpsSurveyQuery({
    language: 'en',
    sessionId: lastSessionFeedback?.sessionId,
  })

  useEffect(() => {
    if (!data) return
    dispatch(setNpsSurveyQuestions(data))
  }, [data])

  const [skipFeedback] = useSkipFeedbackMutation()

  useEffect(() => {
    if (
      (lastSessionFeedback?.requestFeedback &&
        lastSessionFeedback?.sessionId) ||
      shouldAskNps
    ) {
      setOpenPostSessionFeedback(true)
    }
  }, [lastSessionFeedback, shouldAskNps])

  const handleClose = () => {
    skipFeedback({
      sessionId: lastSessionFeedback?.sessionId,
      userId: user?.user?._id,
    })
    setOpenPostSessionFeedback(false)
  }

  const dontShowAgain = () => {
    skipFeedback({
      sessionId: lastSessionFeedback?.sessionId,
      userId: user?.user?._id,
      dontShowAgain: true,
    })
    setOpenPostSessionFeedback(false)
  }

  return (
    <>
      <DashboardWrapper>
        <Stack flexDirection="row" justifyContent="space-between">
          <PageTitle> {t('home')} </PageTitle>
        </Stack>
        <DashboardContent>
          <DashboardLeft>
            <SessionStats />
            {assessmentStatusData?.status === 'PENDING' ? (
              <AssessmentCTA />
            ) : null}
            <UpcomingSessions />
          </DashboardLeft>
          <DashboardRight>
            <DashboardCoaches />
            <DashboardTeamMembers />
          </DashboardRight>
        </DashboardContent>

        {lastSessionFeedback?.sessionId &&
          lastSessionFeedback?.coachName &&
          user?.user?._id && (
            <Modal
              open={openPostSessionFeedback}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClose={handleClose}
            >
              <Box
                style={{
                  width: 600,
                  background: '#FFFFFF',
                  borderRadius: 6,
                  maxHeight: '80vh',
                  overflowY: 'auto',
                  padding: 24,
                  margin: 40,
                }}
              >
                <Box
                  display="flex"
                  justifyContent="right"
                  alignItems="center"
                  mb={4}
                >
                  <Close onClick={handleClose} />
                </Box>
                {shouldAskNps && surveySessionId ? (
                  <NpsSurvey
                    sessionId={surveySessionId}
                    handlePopupClose={() => setOpenPostSessionFeedback(false)}
                    isPopup={true}
                  />
                ) : (
                  <PostSessionUserFeedback
                    sessionId={lastSessionFeedback.sessionId}
                    coachName={lastSessionFeedback.coachName}
                    userId={user.user._id}
                    source={'dashboard'}
                    handlePopupClose={() => setOpenPostSessionFeedback(false)}
                  />
                )}

                {!shouldAskNps ? (
                  <Box textAlign="center">
                    <Typography
                      variant="body1"
                      sx={{
                        display: 'inline-block',
                        ':hover': {
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        },
                      }}
                      onClick={dontShowAgain}
                    >
                      {t('dont_show_again')}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Modal>
          )}
      </DashboardWrapper>
    </>
  )
}
