import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormField } from '../../lib/shared/FormField'
import { useInviteTeamMemberMutation } from '../../app/store/services/manager.service'
import { toast } from 'sonner'

export function InviteMember({
  member,
  onClose,
}: {
  member: any
  onClose: () => void
}) {
  const { t } = useTranslation()
  const { control, handleSubmit } = useForm()
  const [sendInvitation, { isLoading }] = useInviteTeamMemberMutation()

  const onSubmit = handleSubmit(async (values: any) => {
    try {
      const result: any = await sendInvitation(values).unwrap()
      toast.success(result?.message ?? t('invitationSent'))
      onClose()
    } catch (error: any) {
      console.log('error:', error)
      toast.error(error.data?.message ?? t('teamMemberInviteError'))
    }
  })

  return (
    <Dialog open onClose={() => onClose()}>
      <DialogTitle> {t('inviteTeamMember')} </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Stack minWidth={['100%', '400px']} gap="16px">
            <Stack gap="12px">
              <FormField
                name="name"
                label={t('name')}
                control={control}
                variant="filled"
                defaultValue={member?.name ?? ''}
                rules={{
                  required: {
                    value: true,
                    message: 'requiredField',
                  },
                }}
              />
              <FormField
                name="email"
                label={t('email')}
                control={control}
                variant="filled"
                defaultValue={member?.email ?? ''}
                rules={{
                  required: {
                    value: true,
                    message: 'requiredField',
                  },
                  // Doesn't handle all cases, leave it to server side validation
                  // pattern: {
                  //   value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/gi,
                  //   message: "invalidEmail",
                  // },
                }}
                type="email"
              />
              <FormField
                name="title"
                label={t('title')}
                control={control}
                variant="filled"
                defaultValue={member?.title ?? ''}
                rules={{
                  required: {
                    value: true,
                    message: 'requiredField',
                  },
                }}
              />
            </Stack>
            <Button
              size="large"
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              {t(isLoading ? 'wait' : 'sendInvitation')}
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  )
}
