import { CircularProgress } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { useGetAssessmentQuestionsQuery } from '../../app/store/services/manager.service'
import { setAssessmentQuestions } from '../../app/store/slices/uiSlice'
import { useAppDispatch, useAppSelector } from '../../app/store/store.config'
import { useAuth } from '../../hooks/useAuth'
import { AssessmentLoader } from './assessment.style'

export function AssessmentRedirect() {
  const { user } = useAuth()
  const [searchParams] = useSearchParams()
  const { data, isFetching } = useGetAssessmentQuestionsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const pathname = useMemo(() => location.pathname, [])

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const assessment = useAppSelector((store) => store.ui.assessment)

  useEffect(() => {
    if (!user) {
      navigate(`/login?nextURL=${pathname}?${searchParams.toString()}`)
    }
  }, [user, navigate, pathname, searchParams])

  useEffect(() => {
    if (data && data.questions.length > 0) {
      dispatch(setAssessmentQuestions(data))
      navigate(`/assessment/${data.questions[0].friendlyID}`)
    }
  }, [data, dispatch, navigate])

  return isFetching || !data || assessment.questions.length === 0 ? (
    <AssessmentLoader>
      <CircularProgress />
    </AssessmentLoader>
  ) : (
    <Outlet />
  )
}
