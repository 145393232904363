import { useToast } from '@chakra-ui/react'
import { DyteProvider, useDyteClient } from '@dytesdk/react-web-core'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useGetMeetingInfoQuery } from '../../../app/store/services/meeting.service'
import { RootState } from '../../../app/store/store.config'
import { useHideChatlio } from '../../../hooks/useHideChatlio'
import GroupFacetime from './components/GroupFacetime'
import Initializing from './components/Initializing'
import MeetingEnded from './components/MeetingEnded'
import { useSpeechRecognition } from './hooks/useSpeechRecognition'
import { usePostHog } from 'posthog-js/react'
import Facetime from './components/Facetime'
import { DyteDialogManager, DyteSetupScreen } from '@dytesdk/react-ui-kit'

// interface Props {
//   participant: "user" | "coach";
// }
const InAppMeeting = () => {
  const { sessionId, participantId } = useParams() as any

  console.log(
    'InAppMeeting sessionId:',
    sessionId,
    'participantId:',
    participantId,
  )
  const toast = useToast()

  const navigate = useNavigate()
  const meetingInitialized = useRef(false)
  const chatSocketId = useSelector((state: RootState) => state.socket.socketId)
  const posthog = usePostHog()
  const { data, isLoading, error } = useGetMeetingInfoQuery({
    sessionId,
    participantId,
  })
  const { t } = useTranslation()

  const [micAccess, setMicAccess] = useState(false)
  const [cameraAccess, setCameraAccess] = useState(false)
  const [meeting, initMeeting] = useDyteClient()
  const authToken = useMemo(
    () => data?.meeting?.authToken,
    [data?.meeting?.authToken],
  )
  const useStt = useMemo(() => data?.meeting?.useStt, [data?.meeting?.useStt])
  const roomName = useMemo(
    () => data?.meeting?.roomName,
    [data?.meeting?.roomName],
  )
  const participant = useMemo(() => data?.participant, [data?.participant])
  const isCoach = useMemo(() => data?.isCoach, [data?.isCoach])

  const [roomJoined, setRoomJoined] = useState(false)
  const [roomLeft, setRoomLeft] = useState(false)

  const { speechSocket, onStartSpeechRecognition, onStopSpeechRecognition } =
    useSpeechRecognition({
      meeting,
      sessionId,
      useStt,
      participant,
      isCoach,
    })

  useEffect(() => {
    const micPermission = 'microphone' as PermissionName
    navigator.permissions.query({ name: micPermission }).then((result) => {
      setMicAccess(result.state === 'granted')

      if (result.state === 'prompt') {
        result.onchange = () => {
          setMicAccess(result.state === 'granted')
        }
      }
    })
    const cameraPermission = 'camera' as PermissionName
    navigator.permissions.query({ name: cameraPermission }).then((result) => {
      setCameraAccess(result.state === 'granted')

      if (result.state === 'prompt') {
        result.onchange = () => {
          setCameraAccess(result.state === 'granted')
        }
      }
    })
  }, [])

  useEffect(() => {
    if (error) {
      console.log('error:', error)
      toast({
        status: 'error',
        title: t('error'),
        description: error.toString(),
        isClosable: true,
      })
    }
  }, [error, t, toast])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        window.location.reload()
      }
    }, 60000) // 1 minutes
    return () => clearTimeout(timer)
  }, [isLoading])

  useEffect(() => {
    if (!authToken || meetingInitialized.current) return
    console.log('initMeeting, authToken:', authToken)
    initMeeting({ authToken })
    meetingInitialized.current = true
    posthog.capture('inapp_meeting_init', {
      authToken,
      roomName,
    })
  }, [authToken, sessionId, initMeeting, roomName, posthog])

  useEffect(() => {
    if (!meeting || !participant) return

    meeting.self.on('roomJoined', () => {
      setRoomJoined(true)
      onStartSpeechRecognition()
    })
    // Adding chatSocketId to dependencies so that we can trigger restart speech recognition when server restarts
  }, [meeting, participant, sessionId, onStartSpeechRecognition])

  useEffect(() => {
    if (!meeting || !chatSocketId || !participant) return

    // When server restarts, chatSocketId changes and we need to restart speech recognition
    if (meeting.self.roomJoined) {
      console.log('restarting speech recognition')
      onStartSpeechRecognition()
    }
  }, [meeting, participant, sessionId, chatSocketId, onStartSpeechRecognition])

  useEffect(() => {
    if (!meeting || !speechSocket || !participant) return

    meeting.self.on('roomLeft', () => {
      console.log('on::roomLeft')
      setRoomLeft(true)
      onStopSpeechRecognition()
      // if (history.length > 2) {
      // navigate(-1);
      // }
    })
  }, [
    meeting,
    participant,
    sessionId,
    speechSocket,
    navigate,
    onStopSpeechRecognition,
  ])
  useHideChatlio()

  if (!meeting && roomJoined && roomLeft) {
    return <MeetingEnded sessionId={sessionId} roomName={roomName} />
  }
  if (!meeting || isLoading) {
    return (
      <Initializing
        isCoach={participant === 'coach'}
        sessionId={sessionId}
        roomName={roomName}
        micAccess={micAccess}
        cameraAccess={cameraAccess}
      />
    )
  }

  Object.assign(window, { meeting })

  if (!roomJoined && data?.isIndividualCoaching) {
    // mixpanel.track("inapp_meeting_setup", {
    //   eventId,
    //   roomName: data?.roomName,
    // });
    return (
      <div className="h-full w-full p-4 flex flex-col bg-black text-white overflow-hidden">
        <DyteDialogManager meeting={meeting} />
        <DyteSetupScreen meeting={meeting} style={{ height: '360px' }} />
      </div>
    )
  }

  return (
    <div
      id="dyte-meeting"
      className="h-full"
      style={{ backgroundColor: '#222' }}
    >
      <DyteProvider value={meeting} fallback={<div>{t('loading')}</div>}>
        {data?.isIndividualCoaching ? (
          <Facetime
            meeting={meeting}
            isCoach={data?.isCoach}
            sessionId={sessionId}
            userId={participantId}
            coachName={data?.coachName}
          />
        ) : (
          <GroupFacetime
            meeting={meeting}
            isCoach={data?.isCoach}
            sessionId={sessionId}
            userId={participantId}
            coachName={data?.coachName}
          />
        )}
      </DyteProvider>
    </div>
  )
}

export default InAppMeeting
