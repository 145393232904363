import {
  Box,
  Divider,
  Stack,
  Typography,
  TextField,
  Container,
} from '@mui/material'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiChevronRight } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { itemSelect } from '~/app/store/slices/npsSlice'
import TitleDescription from './TitleDescription'
import _ from 'lodash'

const SelectOption: FC<any> = ({
  option,
  selected,
  onItemSelected,
  subItem,
  readonly,
  type,
}) => {
  const isListType = type === 'list'
  const isSelected = selected.has(option.value)

  return (
    <Box
      bgcolor={isSelected ? 'primary.main' : isListType ? '#f0f0f0' : '#fff'}
      boxShadow="0 2px 10px rgba(0,0,0,.1)"
      mb={2}
      borderRadius={100}
      key={option.value}
      onClick={() => (readonly ? null : onItemSelected(option.value))}
      sx={{
        '&:active': {
          backgroundColor: isListType ? undefined : 'primary.dark',
        },
        cursor: 'pointer',
        userSelect: 'none',
      }}
    >
      <Box sx={{ padding: subItem ? 1.5 : 2.5 }}>
        <Typography color={isSelected ? '#fff' : '#000'} textAlign="center">
          {option.label}
        </Typography>
      </Box>
    </Box>
  )
}

const SelectionQuestion: FC<any> = ({
  readonly = false,
  initiallySelected = [],
  answer,
  question,
  onSubmit,
  type = undefined,
}) => {
  const [selected, setSelected] = useState(
    answer && answer.length > 0 ? new Set(answer) : new Set(initiallySelected),
  )
  const dispatch = useDispatch()
  const minSelection = question.minSelectionLimit
  const maxSelection = question.maxSelectionLimit
  const [expanded, setExpanded] = useState(
    readonly
      ? new Set(question.options.map((option: any) => option.value))
      : new Set(question.options.length > 0 ? [question.options[0].value] : []),
  )
  const { t } = useTranslation()
  const [otherValue, setOtherValue] = useState('')

  const user = useSelector((state: any) => state.auth.user)
  const radio = question.type === 'select'
  const showOtherOption = useMemo(() => {
    return (
      question.hasOtherText &&
      selected.size === 1 &&
      (_.last(question.options) as any).value === selected.values().next().value
    )
  }, [selected, question])

  const onItemSelected = (item: any) => {
    if (readonly) {
      return
    }

    const answers = new Set(selected)

    if (radio) {
      answers.clear()
      answers.add(item)
    } else {
      if (selected.has(item)) {
        answers.delete(item)
      } else {
        answers.add(item)
      }
    }
    setSelected(answers)

    if (radio && answers.size === 0) {
      // Use Snackbar or another method for error feedback
      alert(t('select_option')) // Replace with a better feedback mechanism
    } else if (answers.size < minSelection || answers.size > maxSelection) {
      const errorMessage =
        minSelection === maxSelection
          ? t('select_x_items', { count: minSelection })
          : t('select_between_x_y_items', {
              min: minSelection,
              max: maxSelection,
            })
      // Use Snackbar or another method for error feedback
      alert(errorMessage) // Replace with a better feedback mechanism
    } else {
      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: Array.from(answers),
          otherAnswer: showOtherOption ? otherValue : null,
        }),
      )

      onSubmit()
    }
  }

  return (
    <Container>
      <TitleDescription
        title={question.title.replaceAll('{{username}}', user)}
        description={question.description?.replaceAll('{{username}}', user)}
        attachment={question.attachment}
      />
      <Stack spacing={2}>
        <Box>
          {question.options.map((option: any) => {
            if (option.children?.length > 0) {
              const groupExpanded = expanded.has(option.value)
              const onPress = () => {
                const newExpanded = new Set(expanded)
                if (groupExpanded) {
                  newExpanded.delete(option.value)
                } else {
                  newExpanded.add(option.value)
                }
                setExpanded(newExpanded)
              }

              const selectedCount = option.children.filter((item: any) =>
                selected.has(item.value),
              ).length

              return (
                <Box key={option.value}>
                  <Box
                    mb={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    onClick={onPress}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#eee' },
                      '&:active': { backgroundColor: '#ddd' },
                      py: 1.5,
                      borderRadius: '10px',
                      userSelect: 'none',
                    }}
                  >
                    {!readonly && selectedCount > 0 && (
                      <Box
                        width={32}
                        height={32}
                        borderRadius="50%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="primary.main"
                        mr={1}
                      >
                        <Typography color="#fff" fontWeight="700">
                          {selectedCount}
                        </Typography>
                      </Box>
                    )}
                    <Stack spacing={0.5} textAlign="center">
                      <Typography variant="h6">{option.label}</Typography>
                      {option.description && (
                        <Typography variant="body2" fontWeight="300">
                          {option.description}
                        </Typography>
                      )}
                    </Stack>
                    <Box
                      sx={{
                        transform: groupExpanded ? 'rotate(90deg)' : 'none',
                      }}
                    >
                      <BiChevronRight size={30} />
                    </Box>
                  </Box>
                  <Container maxWidth="sm">
                    {groupExpanded &&
                      option.children.map((childItem: any) => (
                        <SelectOption
                          subItem
                          readonly={readonly}
                          key={childItem.value}
                          option={childItem}
                          selected={selected}
                          onItemSelected={onItemSelected}
                          type={type}
                        />
                      ))}
                  </Container>
                  <Divider style={{ marginBottom: 16, marginTop: 16 }} />
                </Box>
              )
            } else {
              return (
                <SelectOption
                  readonly={readonly}
                  key={option.value}
                  option={option}
                  selected={selected}
                  onItemSelected={onItemSelected}
                  type={type}
                />
              )
            }
          })}
          {showOtherOption && (
            <TextField
              fullWidth
              multiline
              rows={4}
              value={otherValue}
              onChange={(event) => setOtherValue(event.target.value)}
              placeholder={question.otherTextPlaceholder}
            />
          )}
        </Box>
      </Stack>
    </Container>
  )
}

export default SelectionQuestion
