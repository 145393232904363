import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { envVars } from '../../../constants/envVars'
import { RootState } from '../store.config'

export interface LastSessionFeedbackParam {
  requestFeedback: boolean
  coachName?: string
  sessionId?: string
}

export const feedbackService = createApi({
  reducerPath: 'feedbackApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envVars.BASE_URL,
    prepareHeaders: (headers, api) => {
      const store = api.getState() as RootState
      if (store.auth.user) {
        headers.set('Authorization', `Bearer ${store.auth.user.token}`)
      }
      return headers
    },
  }),

  endpoints: (builder) => ({
    getLastSessionFeedback: builder.query<
      LastSessionFeedbackParam,
      string | undefined
    >({
      query: (userId: any) => ({
        url: `feedback/last-session-feedback/${userId}`,
      }),
    }),
    skipFeedback: builder.mutation<
      unknown,
      {
        sessionId: string | undefined
        userId: string | undefined
        dontShowAgain?: boolean
      }
    >({
      query: (body) => ({
        url: 'feedback/skip-session-feedback',
        method: 'POST',
        body,
      }),
    }),
    getSessionDetails: builder.query<
      { sessionId: string; coachName: string } | undefined,
      { sessionId: string | undefined }
    >({
      query: (params) => ({
        url: `feedback/session-details`,
        params,
      }),
    }),
  }),
})

export const {
  useGetLastSessionFeedbackQuery,
  useSkipFeedbackMutation,
  useGetSessionDetailsQuery,
} = feedbackService
