import { ChatHeader } from './chat-header/ChatHeader'
import { ChatMessagesList, ChatboxWrapper } from './chatbox-style'
import { ChatEditor } from './chat-editor/ChatEditor'
import { useAuth } from '../../../hooks/useAuth'
import { useChatUtils } from '../../../app/providers/chat-utils/useChatUtils'
import { ChatBubble } from './chat-bubble/ChatBubble'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material'
import { generateTwoLetterName } from '../../../lib/utils/generateTwoLetterName'
import { stringToColor } from '../../../lib/utils/stringToColor'
import { Close } from '@mui/icons-material'
import { Center } from '@chakra-ui/react'

export interface ChatWith {
  type: 'group' | 'single'
  data: {
    avatar?: string
    title: string
    isOnline: boolean
    typing?:
      | boolean
      | {
          name: string[]
        }
  }
  subTitle: string
}
export interface ChatBoxProps {
  chatId: string
}

export function ChatBox({ chatId }: ChatBoxProps) {
  const { user } = useAuth()
  const { handleNewMessage, currentChat, messagesLoading, handleRead } =
    useChatUtils()
  const partnerType = currentChat?.partnerType
  const coach = currentChat?.coach
  const team = currentChat?.team
  const group = currentChat?.group
  const users = currentChat?.users
  const partnerUser = users?.find((item) => item._id !== user?.user._id)
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const title =
    partnerType === 'coach'
      ? coach?.profile.name || ''
      : partnerType === 'group'
        ? group?.title || ''
        : partnerUser?.profile.name || ''

  const avatar =
    partnerType === 'coach'
      ? coach?.profile.picture
      : partnerType === 'group'
        ? group?.avatar
        : partnerUser?.profile.picture

  const fromId =
    partnerType === 'coach'
      ? coach?._id
      : partnerType === 'group'
        ? team?._id
        : partnerUser?._id

  const subTitle =
    (partnerType === 'coach'
      ? t('coach')
      : partnerType === 'group'
        ? t('members', {
            members:
              (currentChat?.users.length ?? 0) + (currentChat?.coach ? 1 : 0),
          })
        : partnerUser?.email) || ''

  useEffect(() => {
    if (currentChat?.data && currentChat.data.length > 0) {
      const lastItem = currentChat.data[currentChat.data.length - 1]
      document.getElementById(`chat_bubble_${lastItem._id}`)?.scrollIntoView()
    }
  }, [currentChat])

  return (
    <ChatboxWrapper className={messagesLoading ? 'loading' : ''}>
      <ChatHeader
        data={{
          data: {
            isOnline: false,
            title,
            avatar,
            typing: false,
          },
          subTitle,
          type: partnerType === 'group' ? 'group' : 'single',
        }}
        onGroup={() => setOpen(true)}
      />
      <ChatMessagesList>
        {currentChat?.data.length ? (
          currentChat?.data.map((message) => (
            <ChatBubble
              onMount={handleRead}
              key={`chat_msg_${message._id}`}
              content={{
                read: message?.read,
                id: message._id,
                date: message.createdAt,
                type: partnerType === 'group' ? 'group' : 'single',
                message: message.message,
                file: message.file ?? {},
                isMe: message.sentBy._id === user?.user._id,
                userId: user?.user._id || '',
                chatId,
                from: {
                  id: fromId!,
                  avatar: message.sentBy.profile.picture,
                  name: message.sentBy.profile.name,
                  email: message.sentBy.email,
                },
              }}
            />
          ))
        ) : (
          <Center h="calc(100vh - 250px)">{t('noMessagesYet')}</Center>
        )}
      </ChatMessagesList>

      <ChatEditor
        onSend={({ text, file }) =>
          handleNewMessage({
            chatId,
            senderId: user?.user._id || '',
            text,
            file,
          })
        }
      />
      {messagesLoading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
      {partnerType === 'group' && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            sx: (theme) => ({
              minWidth: '500px',
              [theme.breakpoints.down('md')]: { minWidth: 'calc(100% - 16px)' },
            }),
          }}
        >
          <DialogTitle
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: '8px 12px 8px 16px',
              borderBottom: `1px solid ${theme.palette.divider}`,
            })}
          >
            {t('chatMembers')}{' '}
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            <Stack divider={<Divider />}>
              {structuredClone(currentChat?.users || [])
                .sort((a, b) => (a.profile.name > b.profile.name ? 1 : -1))
                .map((chatUser) => (
                  <ListItemButton key={chatUser._id}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          background: stringToColor(
                            chatUser.profile?.name || '',
                          ),
                        }}
                        src={chatUser.profile?.picture}
                      >
                        {generateTwoLetterName(chatUser.profile.name || '')}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={chatUser.profile.name}
                      secondary={chatUser.title}
                    />
                  </ListItemButton>
                ))}
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </ChatboxWrapper>
  )
}
