import {
  DyteAudioVisualizer,
  DyteAvatar,
  DyteNameTag,
  DyteParticipantTile,
} from '@dytesdk/react-ui-kit'
import { useDyteSelector } from '@dytesdk/react-web-core'
import { useMemo, useRef, useState } from 'react'

import { Box, VStack } from '@chakra-ui/react'
import clsx from 'clsx'
import { createGrid } from 'good-grid'
import { useGridDimensions } from 'good-grid/react'

export const Grid = ({ meeting, displayScreenShareView }: any) => {
  const participants = useDyteSelector((m) => m.participants.active)
  const $grid = useRef<HTMLElement>(null)
  const dimensions = useGridDimensions($grid)

  const [currentEmojiReactions, setCurrentEmojiReactions] = useState<any>({})

  const displayedParticipants: any = displayScreenShareView
    ? [...participants.toArray(), meeting.self]
    : participants.toArray()

  const timeoutMap: any = useRef({})

  const { width, height, getPosition } = useMemo(
    () =>
      createGrid({
        dimensions,
        count: displayedParticipants.length,
        aspectRatio: '4:3',
        gap: 12,
      }),
    [displayedParticipants.length, dimensions],
  )

  meeting.participants.on('broadcastedMessage', ({ payload, ...rest }: any) => {
    if (payload.emojiType) {
      setCurrentEmojiReactions((prevReactions: any) => {
        const newReactions = { ...prevReactions }
        newReactions[payload?.self?.id] = payload.emojiType
        return newReactions
      })

      if (timeoutMap.current[payload?.self?.id]) {
        clearTimeout(timeoutMap.current[payload?.self?.id])
      }

      timeoutMap.current[payload?.self?.id] = setTimeout(() => {
        setCurrentEmojiReactions((prevReactions: any) => {
          const newReactions = { ...prevReactions }
          delete newReactions[payload?.self?.id]
          return newReactions
        })
        delete timeoutMap.current[payload?.self?.id]
      }, 3000)
    }
  })

  return (
    <div
      className={clsx(
        'relative flex place-items-center justify-center flex-1 overflow-hidden',
        displayScreenShareView ? '' : '-m-2',
      )}
    >
      <VStack>
        {!displayScreenShareView && participants.size === 0 && (
          <p className="text-2xl">No one else is here</p>
        )}
      </VStack>

      <div
        className={`${
          displayedParticipants.length > 0 ? 'w-full h-full' : ''
        } flex flex-col`}
      >
        <main
          className="flex-grow flex-shrink-0 relative flex flex-wrap content-center justify-center gap-4 p-4 overflow-clip"
          ref={$grid}
        >
          {displayedParticipants.length > 0
            ? displayedParticipants.map((participant: any, index: number) => {
                // const { top, left } = getPosition(index)
                return (
                  <DyteParticipantTile
                    participant={participant}
                    meeting={meeting}
                    key={participant.id}
                    className={clsx(
                      'absolute transition-all',
                      participant.audioEnabled
                        ? 'border-dyte-blue shadow-md shadow-dyte-blue/30'
                        : 'border-zinc-200',
                    )}
                    style={{
                      width,
                      height,
                      // top,
                      // left,
                    }}
                  >
                    <DyteAvatar participant={participant} size="md" />
                    <DyteNameTag participant={participant} meeting={meeting}>
                      <DyteAudioVisualizer
                        participant={participant}
                        slot="start"
                      />
                    </DyteNameTag>
                    {currentEmojiReactions[participant.id] ? (
                      <Box
                        className={'text-4xl animate-bounce'}
                        sx={{ lg: { fontSize: '24px' } }}
                        position="absolute"
                        top={16}
                        left={16}
                      >
                        {currentEmojiReactions[participant.id]}
                      </Box>
                    ) : null}
                  </DyteParticipantTile>
                )
              })
            : null}
        </main>
      </div>
    </div>
  )
}
