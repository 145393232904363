import { useTranslation } from 'react-i18next'
import {
  OnboardingLeft,
  OnboardingLogo,
  OnboardingRight,
  OnboardingStepLabel,
  OnboardingStepper,
  OnboardingStepperContent,
  OnboardingStepperHeader,
  OnboardingWrapper,
  StepIcon,
  StepSubtitle,
  StpeTitle,
} from './onboarding-layout.style'
import { Step } from '@mui/material'
import { useMemo } from 'react'
import StepCheck from '../../assets/icons/StepCheck'
import { Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

const stepRoutes = ['getting-to-know', 'connect-calendar']

export function OnboardingLayout() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const activeStep = stepRoutes.findIndex((item) => pathname.includes(item))
  const { user } = useAuth()

  const steps = useMemo(
    () => [
      {
        label: t('gettingToKnow'),
        subTitle: t('fromToMinutes', { from: 1, to: 2 }),
        step: 1,
      },
      {
        label: t('connectYourCalendar'),
        subTitle: t('aboutMinutes', { minutes: 2 }),
        step: 2,
      },
      ...(user && user.user.isManager
        ? [
            {
              label: t('selectTeamsCoach'),
              subTitle: t('aboutMinutes', { minutes: 3 }),
              step: 3,
            },
          ]
        : []),
    ],
    [t, user],
  )

  return (
    <OnboardingWrapper>
      <OnboardingLeft>
        <OnboardingLogo>
          <img alt="Hupo" src="/logo-full-white.png" />
        </OnboardingLogo>
        <OnboardingStepper>
          <OnboardingStepperHeader>
            <h1> {t('welcomeToHupo')} </h1>
            <p>
              {t('onboardingSubTitle')
                .split('/n')
                .map((part, index) => (
                  <span key={`subTitle-${index}`}> {part} </span>
                ))}
            </p>
          </OnboardingStepperHeader>
          <OnboardingStepperContent
            orientation="vertical"
            activeStep={activeStep}
          >
            {steps.map((step, index) => (
              <Step key={step.step}>
                <OnboardingStepLabel
                  StepIconComponent={() => (
                    <StepIcon className={activeStep >= index ? 'active' : ''}>
                      {activeStep >= index ? <StepCheck /> : step.step}
                    </StepIcon>
                  )}
                  optional={
                    <StepSubtitle
                      className={activeStep >= index ? 'active' : ''}
                    >
                      {step.subTitle}
                    </StepSubtitle>
                  }
                >
                  <StpeTitle className={activeStep >= index ? 'active' : ''}>
                    {step.label}
                  </StpeTitle>
                </OnboardingStepLabel>
              </Step>
            ))}
          </OnboardingStepperContent>
        </OnboardingStepper>
      </OnboardingLeft>
      <OnboardingRight>
        <Outlet />
      </OnboardingRight>
    </OnboardingWrapper>
  )
}
