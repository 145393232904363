import moment from 'moment'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DashboardCardTitle } from '../../lib/shared/dashboard-card/dashboard-card.style'

export function MoreDiagnosticsAwaiting({
  completedCount,
  totalCount,
  minRequiredCount,
}: {
  completedCount: number
  totalCount: number
  minRequiredCount: number
}) {
  const { t } = useTranslation()
  const date = useMemo(
    () => ({
      start: moment(),
      end: moment().add(30, 'days'),
    }),
    [],
  )

  return (
    <>
      <DashboardCardTitle>
        {' '}
        {t('awaiting_team_submissions')}{' '}
      </DashboardCardTitle>
      <p style={{ fontSize: '14px', paddingTop: '14px' }}>
        {t('no_results_shown_until_x_complete', {
          count: minRequiredCount,
        })}
      </p>
      <p style={{ fontSize: '14px', paddingTop: '4px', marginTop: '14px' }}>
        {t('completed') + ': '}{' '}
        <strong>{completedCount + '/' + totalCount}</strong>
      </p>
    </>
  )
}
