import { Box, CircularProgress } from '@mui/material'
import PostSessionUserFeedback from './PostSessionUserFeedback'
import { useParams } from 'react-router-dom'
import { useGetSessionDetailsQuery } from '~/app/store/services/feedback.service'
import { useEffect } from 'react'
import { toast } from 'sonner'

const PostSessionFeedbackPage = () => {
  const { sessionId, participantId } = useParams<{
    sessionId: string
    participantId: string
  }>()
  const { data: sessionDetails } = useGetSessionDetailsQuery({
    sessionId,
  })

  useEffect(() => {
    if (sessionDetails?.sessionId === null) toast.error('Session not found')
  }, [sessionDetails])

  const urlParams = new URLSearchParams(window.location.search)
  const rating = urlParams.get('rating') || ''
  const source = urlParams.get('source') || ''

  return (
    <Box bgcolor="#FFF" pt={2} minHeight={'100vh'}>
      {sessionDetails?.sessionId && participantId ? (
        <PostSessionUserFeedback
          sessionId={sessionDetails.sessionId}
          userId={participantId}
          coachName={sessionDetails.coachName}
          selectedRating={rating}
          source={source}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

export default PostSessionFeedbackPage
