import {
  CalendarMonthOutlined,
  ChatOutlined,
  FolderOutlined,
  GroupOutlined,
  HomeOutlined,
  QueryStatsOutlined,
  AutoAwesomeOutlined,
} from '@mui/icons-material'

export const managerLinks = [
  {
    label: 'home',
    to: '/',
    icon: HomeOutlined,
  },
  {
    label: 'team-members',
    to: '/team-members',
    icon: GroupOutlined,
  },
  {
    label: 'scheduled-sessions',
    to: '/scheduled-sessions',
    icon: CalendarMonthOutlined,
  },
  {
    label: 'progress',
    to: '/progress',
    icon: QueryStatsOutlined,
  },
  {
    label: 'team-chat',
    to: '/team-chat',
    icon: ChatOutlined,
  },
  {
    label: 'resources',
    to: '/resources',
    icon: FolderOutlined,
  },
  {
    label: 'hupoHighlights',
    to: '/notes',
    icon: AutoAwesomeOutlined,
  },
]

export const teamMemberLinks = [
  {
    label: 'home',
    to: '/',
    icon: HomeOutlined,
  },
  {
    label: 'scheduled-sessions',
    to: '/scheduled-sessions',
    icon: CalendarMonthOutlined,
  },
  {
    label: 'progress',
    to: '/progress',
    icon: QueryStatsOutlined,
  },
  {
    label: 'team-chat',
    to: '/team-chat',
    icon: ChatOutlined,
  },
  {
    label: 'team-members',
    to: '/team-members',
    icon: GroupOutlined,
  },
  {
    label: 'resources',
    to: '/resources',
    icon: FolderOutlined,
  },
  {
    label: 'hupoHighlights',
    to: '/notes',
    icon: AutoAwesomeOutlined,
  },
]
