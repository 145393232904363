import { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

import { Box, Icon } from '@chakra-ui/react'
import { Popover } from '@mui/material'
import { usePostHog } from 'posthog-js/react'
import { getUnifiedCallLink } from '../../../../call/calllink'

export const AlternativeLinkFromMenuOneOnOne = ({
  sessionId,
  participantId,
  isCoach,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [menuItemExpanded, setMenuItemExpanded] = useState(false)
  const posthog = usePostHog()

  const alternativeLinkFromMenu = getUnifiedCallLink({
    sessionId,
    participantId,
    isCoach,
    referrer: 'inapp-alternative-app-menu',
    skipInApp: true,
  })

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
    setMenuItemExpanded(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenuItemExpanded(false)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box>
      <Box onClick={handleOpen}>
        <Icon as={menuItemExpanded ? IoIosArrowUp : IoIosArrowDown} m="4" />
      </Box>
      <Popover
        className="w-2/5"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="bg-slate-800 text-white p-4">
          <p className="mb-2">Having issues with inapp call?</p>
          <p className="mb-2">
            Try using this alternative link and let your{' '}
            {isCoach ? 'coachee' : 'coach'} know to do the same (from same
            menu):
          </p>
          <a
            href={alternativeLinkFromMenu}
            target="_blank"
            className="text-blue-400 text-sm"
            onClick={() =>
              posthog.capture('inapp_meeting_alternative_link_click', {
                alternativeLink: alternativeLinkFromMenu,
              })
            }
          >
            {alternativeLinkFromMenu}
          </a>
        </div>
      </Popover>
    </Box>
  )
}
