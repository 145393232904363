import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  AssessmentQuestion,
  AssessmentValue,
} from '../../../interfaces/main-interfaces'

export interface IUI {
  sidebarVisible: boolean
  assessment: {
    questions: AssessmentQuestion[]
    values: AssessmentValue[]
  }
  assessmentResults: {
    type: string
    language: string
    answers: any
    ratings: any
  }
}

const initialState: IUI = {
  sidebarVisible: true,
  assessment: {
    questions: [],
    values: [],
  },
  assessmentResults: {
    answers: {},
    language: 'en',
    ratings: {},
    type: 'assessment',
  },
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSidebarVisible: (state, action) => {
      state.sidebarVisible = action.payload
    },
    setAssessmentQuestions: (state, action) => {
      state.assessment = action.payload
    },
    setAssessmentResult: (state, action) => {
      state.assessmentResults = action.payload
    },
  },
})

export default uiSlice

export const {
  setSidebarVisible,
  setAssessmentQuestions,
  setAssessmentResult,
} = uiSlice.actions
