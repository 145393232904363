import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import { TextFieldProps } from '@mui/material'
import { useState } from 'react'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export type FormFieldProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<TextFieldProps, 'value' | 'defaultValue'>

export function AppDatePicker<T extends FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  onChange,
  ...props
}: FormFieldProps<T>) {
  const { t } = useTranslation()
  const [focused, setFocused] = useState(false)
  const [open, setOpen] = useState(false)
  const {
    field: { value, onChange: onFieldChange, ...fieldProps },
    fieldState,
  } = useController({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  })

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          value={moment(value)}
          disablePast
          open={open}
          onClose={() => setOpen(false)}
          // minutesStep={15}
          format="D MMM, YYYY"
          slotProps={{
            textField: {
              ...fieldProps,
              ...props,
              helperText: fieldState.error
                ? t(fieldState.error.message as string)
                : props.helperText,
              InputProps: {
                disableUnderline: true,
                readOnly: true,
              },
              onBlur: () => {
                setFocused(false)
                fieldProps.onBlur()
              },
              onFocus: (event) => {
                setFocused(true)
                props.onFocus?.(event)
              },
              error: !!fieldState.error,
              onClick: () => setOpen(true),
              defaultValue: defaultValue,
              InputLabelProps: {
                shrink:
                  focused ||
                  props.InputLabelProps?.shrink ||
                  typeof value === 'number' ||
                  !!value,
              },
              onChange: (event) => {
                onFieldChange(event)
                onChange?.(event)
              },
            },
            field: {
              readOnly: true,
            },
          }}
          onChange={(newValue) => {
            newValue &&
              onFieldChange?.({
                target: { name, value: moment(newValue).toISOString() },
                value: moment(newValue).toISOString(),
              } as any)
            onChange?.({
              target: { name, value: moment(newValue).toISOString() },
              value: moment(newValue).toISOString(),
            } as any)
          }}
        />
      </LocalizationProvider>
    </>
  )
}
