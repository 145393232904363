import { QuestionItem } from '../../../mock/mockAssessment'
import { LQItem, LQWrapper } from './question-types.style'

export function ListQuestion({ questions }: { questions: QuestionItem[] }) {
  return (
    <LQWrapper>
      {questions.map((item) => (
        <LQItem className="disabled" key={item.value}>
          {item.label}
        </LQItem>
      ))}
    </LQWrapper>
  )
}
