import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envVars } from '../../../constants/envVars'
import { RootState } from '../store.config'
import {
  GoogleAuthResult,
  PasswordForgotRequest,
  PasswordForgotResponse,
  PasswordResetRequest,
  PasswordResetResponse,
  SignupResult,
  UserInfo,
} from '../../../interfaces/entities/auth.entities'

export const AUTH_TAG = {
  USER: 'USER',
}

export const authService = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envVars.BASE_URL,
    prepareHeaders: (headers, api) => {
      const store = api.getState() as RootState
      if (store.auth.user) {
        headers.set('Authorization', `Bearer ${store.auth.user.token}`)
      }
      return headers
    },
  }),
  tagTypes: [AUTH_TAG.USER],
  endpoints: (builder) => ({
    googleAuth: builder.mutation<
      GoogleAuthResult,
      { code: string; timeZone: string }
    >({
      query: (body) => ({
        url: 'user/google/auth',
        body,
        method: 'POST',
      }),
    }),
    googleSignIn: builder.mutation<
      GoogleAuthResult,
      { accessToken: string; timeZone: string }
    >({
      query: (body) => ({
        url: 'user/google/sign-in',
        body,
        method: 'POST',
      }),
    }),
    checkUser: builder.mutation<GoogleAuthResult, { email: string }>({
      query: (params) => ({
        url: 'user/check',
        params,
      }),
    }),
    getMe: builder.query<UserInfo, void>({
      query: () => ({
        url: 'user/me',
      }),
      providesTags: [AUTH_TAG.USER],
    }),
    getMeReq: builder.mutation<UserInfo, void>({
      query: () => ({
        url: 'user/me',
        method: 'GET',
      }),
    }),
    adminLogin: builder.mutation<
      GoogleAuthResult,
      { email: string; adminSecret: string }
    >({
      query: (body) => ({
        url: 'user/login',
        body,
        method: 'POST',
      }),
    }),
    onboardingComplete: builder.mutation<void, void>({
      query: (body) => ({
        url: 'user/onboarding-complete',
        body,
        method: 'POST',
      }),
    }),
    signup: builder.mutation<
      SignupResult,
      {
        name: string
        email: string
        password: string
        timeZone: string
      }
    >({
      query: (body) => ({
        url: 'user/signup',
        body,
        method: 'POST',
      }),
    }),
    login: builder.mutation<
      SignupResult,
      {
        email: string
        password: string
        adminSecret: string
      }
    >({
      query: (body) => ({
        url: 'user/login',
        body,
        method: 'POST',
      }),
    }),
    forgotPassword: builder.mutation<
      PasswordForgotResponse,
      PasswordForgotRequest
    >({
      query: (body) => ({
        url: 'user/forgot-password',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<
      PasswordResetResponse,
      PasswordResetRequest
    >({
      query: (body) => ({
        url: 'user/reset-password',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGoogleAuthMutation,
  useGoogleSignInMutation,
  useCheckUserMutation,
  useGetMeQuery,
  useGetMeReqMutation,
  useLoginMutation,
  useAdminLoginMutation,
  useOnboardingCompleteMutation,
  useSignupMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authService
