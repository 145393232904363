import {
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
} from '@fullcalendar/core/index.js'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import { useMediaQuery } from 'react-responsive'
import { Button, Stack } from '@mui/material'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  useCheckSlotsQuery,
  useGetSessionStatsQuery,
  useGetSessionsQuery,
} from '../../../app/store/services/manager.service'
import {
  DEFAULT_INDIVIDUAL_SESSION_DURATION,
  DEFAULT_TEAM_SESSION_DURATION,
} from '../../../constants'
import { useAuth } from '../../../hooks/useAuth'
import { Session } from '../../../interfaces/main-interfaces'
import { LoadingOverlay } from '../../../lib/shared/LoadingOverlay'
import { getStartTime } from '../../../lib/utils/getStartTime'
import { ViewEvent } from '../../../lib/widgets/view-event/ViewEvent'
import { CreateSession } from './create-session/CreateSession'
import { CreateSessionDTO } from './dto/scheduling.dto'

const MOBILE_HEADER = {
  start: 'title',
  center: 'prev,today,next',
  end: 'timeGridDay,listWeek',
}

const DESKTOP_HEADER = {
  // start: 'title',
  right: 'dayGridMonth,timeGridWeek,timeGridDay today prev,next',
}

export function ScheduledSessions() {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' })
  const { t } = useTranslation()
  const [searchParams, _] = useSearchParams()
  const sessionId = searchParams.get('sessionId')
  const dateParam = searchParams.get('date') ?? undefined
  const actionParam = searchParams.get('action') ?? undefined

  const [create, setCreate] = useState<CreateSessionDTO | null>(null)
  const [viewType, setViewType] = useState('timeGridWeek')
  const [currentSession, setCurrentSession] = useState<Session | null>(null)
  const [date, setDate] = useState({
    start: moment(dateParam).startOf('week').toISOString(),
    end: moment(dateParam).endOf('week').toISOString(),
  })

  const { user } = useAuth()
  const timezone = user?.user.timezone
  const isManager = user?.user.isManager

  const { data: _blockedSlots } = useCheckSlotsQuery(
    {
      start: date.start,
      end: date.end,
    },
    { refetchOnMountOrArgChange: true, skip: !isManager },
  )

  const { data: sessions, isFetching } = useGetSessionsQuery(
    {
      start: date.start,
      end: date.end,
    },
    { refetchOnMountOrArgChange: true },
  )

  const { data: sessionData } = useGetSessionStatsQuery(void 0, {
    refetchOnMountOrArgChange: true,
  })

  const canBookSession = useMemo(() => {
    if (!sessionData) return { team: true, individual: true }
    return {
      team: sessionData.remainingSessions.team > 0,
      individual: sessionData.remainingSessions.individual > 0,
    }
  }, [sessionData])

  useEffect(() => {
    if (sessionId && sessions?.data) {
      const selectedEvent = sessions?.data.find(
        (session) => session._id === sessionId,
      )
      setCurrentSession(selectedEvent || null)
    }
  }, [sessionId, sessions?.data])

  useEffect(() => {
    if (actionParam === 'book_team_session' && sessions?.defaultTitle) {
      setCreate({
        duration: DEFAULT_TEAM_SESSION_DURATION,
        start: getStartTime().toISOString(),
        type: 'team',
      })
    }
    if (
      actionParam === 'book_individual_session' &&
      sessions?.defaultIndividualTitle
    ) {
      setCreate({
        duration: DEFAULT_INDIVIDUAL_SESSION_DURATION,
        start: getStartTime().toISOString(),
        type: 'individual',
      })
    }
  }, [actionParam, sessions?.defaultTitle, sessions?.defaultIndividualTitle])

  const handleSelect = useCallback((selectedDate: DateSelectArg) => {
    const startTime = moment(selectedDate.startStr)
    const endTime = moment(selectedDate.endStr)
    const duration = moment.duration(endTime.diff(startTime))
    const minutes = duration.asMinutes()

    setCreate({
      duration: minutes < 60 || minutes > 120 ? 90 : minutes,
      start: startTime.toISOString(),
      type: 'team',
    })
  }, [])

  const handleEdit = useCallback((selectedSession: Session) => {
    const startTime = moment(selectedSession.start)
    const endTime = moment(selectedSession.end)
    const duration = moment.duration(endTime.diff(startTime))
    const minutes = duration.asMinutes()
    setCreate({
      duration: minutes,
      start: startTime.toISOString(),
      id: selectedSession._id,
      title: selectedSession.title,
      type: selectedSession.type,
    })
    setCurrentSession(null)
  }, [])

  const handleEditById = useCallback((selectedDate: EventChangeArg) => {
    const id = selectedDate.event.id
    const newStart = selectedDate.event.startStr
    const newEnd = selectedDate.event.endStr
    const startTime = moment(newStart)
    const endTime = moment(newEnd)
    const duration = moment.duration(endTime.diff(startTime))
    const minutes = duration.asMinutes()
    setCreate({
      duration: minutes,
      start: startTime.toISOString(),
      id: id,
      type: selectedDate.event.extendedProps['type'],
    })

    setCurrentSession(null)
  }, [])

  const handleEventClick = useCallback(
    (event: EventClickArg) => {
      const selectedEvent = sessions?.data.find(
        (session) => session._id === event.event.id,
      )
      setCurrentSession(selectedEvent || null)
    },
    [sessions?.data],
  )

  return (
    <Stack gap="24px">
      {isManager && (
        <Stack flexDirection="row" justifyContent="flex-end">
          <Button
            onClick={() =>
              setCreate({
                duration: DEFAULT_TEAM_SESSION_DURATION,
                start: getStartTime().toISOString(),
                type: 'team',
              })
            }
            variant="contained"
          >
            {t('bookTeamSession')}
          </Button>
          <Button
            style={{ marginLeft: '16px' }}
            onClick={() =>
              setCreate({
                duration: DEFAULT_INDIVIDUAL_SESSION_DURATION,
                start: getStartTime().toISOString(),
                type: 'individual',
              })
            }
            variant="contained"
          >
            {t('bookIndividualSession')}
          </Button>
        </Stack>
      )}
      <LoadingOverlay isLoading={isFetching}>
        <FullCalendar
          height={`calc(100vh - ${isManager ? '155px' : '96px'})`}
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            momentPlugin,
            timeGridPlugin,
          ]}
          initialView={isMobileScreen ? 'timeGridDay' : 'timeGridWeek'}
          viewDidMount={(args) => setViewType(args.view.type)}
          editable={isManager && !(viewType === 'dayGridMonth')}
          eventStartEditable={isManager && !(viewType === 'dayGridMonth')}
          eventDurationEditable={isManager && !(viewType === 'dayGridMonth')}
          allDayText={t('allDay')}
          selectable={isManager && !(viewType === 'dayGridMonth')}
          initialDate={date.start}
          buttonText={{
            day: t('day'),
            month: t('month'),
            next: t('next'),
            nextYear: t('nextYear'),
            prev: t('prev'),
            prevYear: t('prevYear'),
            today: t('today'),
            week: t('week'),
          }}
          datesSet={(newDate) =>
            setDate({
              start: moment(newDate.startStr).toISOString(),
              end: moment(newDate.endStr).toISOString(),
            })
          }
          titleFormat={({ start, end }) => {
            const startStr = moment(start).format('D MMM YYYY')

            if (end) {
              const endStr = moment(end).format('D MMM YYYY')
              return `${startStr} - ${endStr} (${timezone})`
            }

            return `${startStr} (${timezone})`
          }}
          headerToolbar={isMobileScreen ? MOBILE_HEADER : DESKTOP_HEADER}
          select={handleSelect}
          selectAllow={(selectInfo) => {
            const duration = moment.duration(
              moment(selectInfo.endStr).diff(moment(selectInfo.start)),
            )
            return duration.asHours() <= 3
          }}
          nowIndicator={true}
          eventClick={handleEventClick}
          events={sessions?.data.map((event) => ({
            title: event.title,
            start: event.start,
            end: event.end,
            id: event._id,
            participants: event.participants,
            type: event.type,
          }))}
          eventChange={handleEditById}
        />
      </LoadingOverlay>
      <CreateSession
        initialData={create}
        defaultTitle={sessions?.defaultTitle ?? ''}
        defaultIndividualTitle={sessions?.defaultIndividualTitle ?? ''}
        open={!!create}
        onClose={() => setCreate(null)}
        canBookSession={canBookSession}
      />
      {currentSession && (
        <ViewEvent
          session={currentSession}
          onEdit={handleEdit}
          onClose={() => setCurrentSession(null)}
        />
      )}
    </Stack>
  )
}
