import { TextField, Container } from '@mui/material'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { itemSelect } from '~/app/store/slices/npsSlice'
import TitleDescription from './TitleDescription'

const TextAreaQuestion: FC<any> = ({ question, isPopup }) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const user: any = useSelector((state: any) => state.auth.user)

  let questionTitle = question.title.replaceAll('{{username}}', user)

  const questionDescription = question.description?.replaceAll(
    '{{username}}',
    user,
  )

  const handleInput = (inputValue: string) => {
    setValue(inputValue)
    dispatch(
      itemSelect({
        questionId: question.friendlyID,
        answers: inputValue,
      }),
    )
  }

  return (
    <>
      <Container>
        <TitleDescription
          title={questionTitle}
          description={questionDescription}
        />
        <TextField
          value={value}
          onChange={(event) => handleInput(event.target.value)}
          placeholder={question.placeholder || 'Enter your feedback here'}
          multiline
          rows={10}
          variant="outlined"
          fullWidth
          sx={{
            backgroundColor: isPopup ? '#fff' : '#222',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ccc',
              },
            },
          }}
          InputProps={{
            style: {
              color: isPopup ? '#000' : '#fff',
            },
          }}
        />
      </Container>
    </>
  )
}

export default TextAreaQuestion
