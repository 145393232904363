import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useResetPasswordMutation } from '../../app/store/services/auth.service'
import { toast } from 'sonner'
import { usePostHog } from 'posthog-js/react'
import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { HeaderLogo } from '~/layout/main/main-layout.style'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export function ResetPassword() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const posthog = usePostHog()
  const [resetPassword, { isLoading }] = useResetPasswordMutation()

  const { token, email } = useParams() as any

  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [errors, setErrors] = useState<string[]>([])

  const handleForgotPassword = async () => {
    let valid = true

    if (!password) {
      setPasswordError(t('password_required'))
      valid = false
    }
    if (confirmPassword !== password) {
      setConfirmPasswordError(t('confrim_password_mismatch'))
      valid = false
    }
    if (!valid) return

    try {
      await resetPassword({ email, password, confirmPassword, token }).unwrap()
      toast.success(t('password_reset_success'))
      posthog.capture('password_reset_success')
      navigate('/login')
    } catch (error: any) {
      if (error.data.message) {
        toast.error(error.data.message)
      }
      posthog.capture('password_reset_fail', {
        message: error?.data?.message,
      })
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  const handlePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    validatePassword(event.target.value)
    setPasswordError('')
  }

  const handleConfirmPasswordInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(event.target.value)
    setConfirmPasswordError('')
  }

  const validatePassword = (password: string) => {
    const requirements = [
      { re: /.{10}/, message: t('password_requirements_length') },
      { re: /[A-Z]/, message: t('password_must_contain_uppercase_letter') },
      { re: /[a-z]/, message: t('password_must_contain_lowercase_letter') },
      {
        re: /^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/,
        message: t('password_must_contain_special_character'),
      },
    ]

    const errors: string[] = []

    for (const req of requirements) {
      if (!req.re.test(password)) {
        errors.push(req.message)
      }
    }
    setErrors(errors)
  }

  return (
    <Box height="100%" bgcolor="#fff" pt={2}>
      <HeaderLogo to="/forgot-password">
        <img alt="Hupo" src="/logo-full.png" />
      </HeaderLogo>
      <Box
        display="flex"
        alignItems={'center'}
        justifyContent="center"
        height="80vh"
      >
        <Stack alignItems="center" gap="16px" width="100%" maxWidth="368px">
          <Typography variant="h4" fontWeight={600} color="primary">
            {t('reset_password')}
          </Typography>
          <TextField
            label="Email"
            color="primary"
            placeholder="Enter your email"
            variant="outlined"
            value={email}
            fullWidth
            sx={{ minWidth: 280, mt: 2 }}
            InputLabelProps={{ shrink: true }}
            disabled={true}
          />
          <TextField
            label="Password"
            placeholder="Enter your password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ color: '#888' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 280, mt: 1 }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={!!passwordError}
            helperText={passwordError}
          />

          {password && errors.length > 0 ? (
            <Box
              borderRadius={1}
              px={2}
              py={1}
              sx={{ width: 368 }}
              bgcolor="#FDEDED"
            >
              {errors.map((error) => (
                <Typography
                  variant="body2"
                  alignContent={'center'}
                  sx={{ my: '4px' }}
                  color="#5F2220"
                >
                  <ErrorOutlineIcon
                    sx={{ mr: 1, width: 24, color: '#D74342' }}
                  />{' '}
                  {error}
                </Typography>
              ))}
            </Box>
          ) : null}

          <TextField
            label="Confirm Password"
            placeholder="Confirm your password"
            variant="outlined"
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={handleConfirmPasswordInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                    sx={{ color: '#888' }}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 280, mt: 1 }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={!!confirmPasswordError}
            helperText={confirmPasswordError}
          />
          <Button
            variant="contained"
            onClick={handleForgotPassword}
            disabled={isLoading}
            fullWidth
            size="large"
            sx={{ mt: 2 }}
          >
            {isLoading ? t('loading') : t('reset_password_button')}
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
