import { AccessTime } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { UpcomingEventWrapper } from './upcoming-event.style'

export interface UpcomingEventProps {
  title: string
  date: string
  id: string
  color: string
  joinlink?: string
}

export function UpcomingEvent({
  id,
  title,
  date,
  color,
  joinlink,
}: UpcomingEventProps) {
  const { t } = useTranslation()

  return (
    <UpcomingEventWrapper sx={{ '--border-color': color }}>
      <Box>
        <div>
          <h4> {title} </h4>
          <p>
            <AccessTime fontSize="small" />{' '}
            {moment(date).format('D MMM, YYYY | h:mm A')}{' '}
          </p>
          <Link
            to={`/scheduled-sessions?sessionId=${id}&date=${moment(
              date,
            ).toISOString()}`}
          >
            {t('viewBookingPage')}
          </Link>
        </div>
        <Button
          variant="contained"
          onClick={() => window.open(joinlink, '_blank')}
        >
          {t('join')}
        </Button>
      </Box>
    </UpcomingEventWrapper>
  )
}
