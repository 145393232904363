import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { envVars } from '../../../constants/envVars'
import { RootState } from '../store.config'

export interface LastSessionFeedbackParam {
  requestNps: boolean
  coachName?: string
  sessionId?: string
}

export const npsService = createApi({
  reducerPath: 'npsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: envVars.BASE_URL,
    prepareHeaders: (headers, api) => {
      const store = api.getState() as RootState
      if (store.auth.user) {
        headers.set('Authorization', `Bearer ${store.auth.user.token}`)
      }
      return headers
    },
  }),

  endpoints: (builder) => ({
    getNpsSurvey: builder.query<any, any>({
      query: (params: any) => ({
        url: `in_app_feedback/nps-survey`,
        params,
      }),
    }),
    postInAppFeedback: builder.mutation({
      query: (body) => ({
        url: '/in_app_feedback/',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetNpsSurveyQuery, usePostInAppFeedbackMutation } = npsService
