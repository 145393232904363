import NpsQuestion from './questiontypes/NpsQuestion'
import SelectionQuestion from './questiontypes/SelectionQuestion'
import TextAreaQuestion from './questiontypes/TextAreaQuestion'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from '@mui/material'
import { usePostInAppFeedbackMutation } from '~/app/store/services/nps.service'
import { RootState, store, useAppSelector } from '~/app/store/store.config'
import { toast } from 'sonner'
import { useEffect, useState } from 'react'
import { Logo } from '../hupohighlights/Logo'
import { usePostHog } from 'posthog-js/react'

const REDIRECT_SECONDS = 3
const HOME_PAGE = `${window.location.protocol}//${window.location.host}`

const Loading = () => {
  return (
    <Box style={{ justifyContent: 'center', marginTop: 50 }}>
      <CircularProgress />
    </Box>
  )
}

const Question = ({ onSubmit, question, isPopup }: any) => {
  switch (question.type) {
    case 'nps': {
      return (
        <NpsQuestion
          key={question._id}
          question={question}
          onSubmit={onSubmit}
          isPopup={isPopup}
        />
      )
    }
    case 'textarea': {
      return (
        <TextAreaQuestion
          key={question._id}
          question={question}
          isPopup={isPopup}
        />
      )
    }
    case 'multiselect':
    case 'select': {
      return (
        <SelectionQuestion
          key={question._id}
          question={question}
          onSubmit={onSubmit}
        />
      )
    }
    default: {
      return (
        <Typography>Not implemented question type: ${question.type}</Typography>
      )
    }
  }
}

export const NpsSurvey = ({
  sessionId,
  handlePopupClose,
  isPopup = false,
}: {
  sessionId: string
  handlePopupClose?: () => void
  isPopup?: boolean
}) => {
  const { t } = useTranslation()
  const posthog = usePostHog()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [redirectionTimer, setRedirectionTimer] = useState(REDIRECT_SECONDS)

  const { questions, answers, surveyType, surveyId } = useAppSelector(
    (store: RootState) => store.nps,
  )

  const [postInAppFeedback] = usePostInAppFeedbackMutation()

  useEffect(() => {
    posthog.capture('nps_survey_displayed', {
      source: isPopup ? 'dashboard' : 'post_call',
    })
  }, [])

  const onSubmit = async (submitButtonClicked?: boolean) => {
    const submittedAnswers = store.getState().nps.answers
    const answersArray = Object.keys(submittedAnswers).map((key) => ({
      questionId: key,
      answer: submittedAnswers[key],
    }))
    const completed = answersArray?.length === questions?.length

    if (submitButtonClicked) {
      if (!isPopup) {
        setIsRedirecting(true)
        const timer = setInterval(() => {
          setRedirectionTimer((prevTimer) => {
            if (prevTimer > 0) {
              return prevTimer - 1
            }
            clearInterval(timer)
            return 0
          })
        }, 1000)
        setTimeout(() => {
          clearInterval(timer)
          window.location.href = HOME_PAGE
        }, REDIRECT_SECONDS * 1000)
      } else if (handlePopupClose) {
        handlePopupClose()
        toast.success(t('thank-you-for-feedback'))
      }
    }

    await postInAppFeedback({
      answers: answersArray,
      surveyType,
      surveyId,
      sessionId,
      completed,
    }).unwrap()
  }

  if (isRedirecting) {
    return (
      <Box
        height={isPopup ? 'auto' : '100vh'}
        bgcolor={isPopup ? '#fff' : '#222'}
      >
        <Container maxWidth="md" sx={{ pt: 3, pb: 6 }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={4}
          >
            <Logo md={5} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="200px"
          >
            <Typography variant="h5" color="#FFF">
              {t('thank_you_for_your_feedback', { redirectionTimer })}
            </Typography>
          </Box>
        </Container>
      </Box>
    )
  }

  if (!questions) {
    return <Loading />
  }

  return (
    <Box
      height={isPopup ? 'auto' : undefined}
      bgcolor={isPopup ? '#fff' : '#222'}
    >
      <Container
        maxWidth="md"
        sx={{
          p: isPopup ? 0 : 4,
          color: isPopup ? 'text.primary' : '#fff',
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
          <Logo md={5} />
        </Box>
        {!isPopup ? (
          <>
            <Typography variant="h5" fontWeight="600" mb={3} textAlign="center">
              {t('session_completed')} 🎉!
            </Typography>
            <Divider />
          </>
        ) : null}
        {('inapp-nps' in answers
          ? questions
          : questions.filter((q) => q.friendlyID === 'inapp-nps')
        )?.map((question, i) => (
          <Box mt={4}>
            {i !== 0 ? (
              <Divider
                style={{ marginTop: 16, marginBottom: 24, opacity: 0.6 }}
              />
            ) : null}
            <Question
              question={question}
              onSubmit={onSubmit}
              isPopup={isPopup}
            />
          </Box>
        ))}
        {Object.keys(answers).length > 0 ? (
          <Box display={'flex'} justifyContent={'center'}>
            <Button
              onClick={() => onSubmit(true)}
              variant="contained"
              color="primary"
              sx={{
                borderRadius: 100,
                height: '50px',
                fontSize: 16,
                width: '240px',
                my: 5,
              }}
            >
              {t('submit')}
            </Button>
          </Box>
        ) : null}
      </Container>
    </Box>
  )
}
