import { useTranslation } from 'react-i18next'
import {
  OnboardingLeft,
  OnboardingLogo,
  OnboardingStepper,
  OnboardingStepperHeader,
  OnboardingWrapper,
} from '../../layout/onboarding/onboarding-layout.style'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import GoogleIcon from '../../assets/icons/Google'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '../../app/store/store.config'
import {
  useGoogleSignInMutation,
  useLoginMutation,
} from '../../app/store/services/auth.service'
import { useGoogleLogin } from '@react-oauth/google'
import { setUser } from '../../app/store/slices/authSlice'
import { toast } from 'sonner'
import { KeyboardEvent, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#fff',
    },
    '&:hover fieldset': {
      borderColor: '#fff',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fff',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#fff',
    '&.Mui-focused': {
      color: '#fff',
    },
  },
  '& .MuiInputBase-input': {
    color: '#fff',
  },
})

export function LoginPage() {
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [googleSignin, { isLoading }] = useGoogleSignInMutation()
  const [login, { isLoading: loginLoading }] = useLoginMutation()

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      try {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const response = await googleSignin({
          accessToken: access_token,
          timeZone,
        }).unwrap()
        dispatch(setUser(response))

        const nextURL = searchParams.get('nextURL')
        navigate(nextURL ?? '/')
      } catch (error: any) {
        if (error.data.message) {
          toast.error(error.data.message)
        }
      }
    },
    onError: (error) => {
      if (error.error_description) {
        toast.error(error.error_description)
        console.log(error.error_uri)
      } else {
        toast.error(t('somethingWentWrong'))
      }
    },
  })

  const handleLogin = async () => {
    if (!email) {
      toast.error(t('email_required'))
      return
    }

    if (!password) {
      toast.error(t('password_required'))
      return
    }

    try {
      const adminSecret = document.getElementById(
        'adminSecret',
      ) as HTMLInputElement | null

      const response = await login({
        email,
        password,
        adminSecret: adminSecret?.value ?? '',
      }).unwrap()
      dispatch(setUser(response))
      const nextURL = searchParams.get('nextURL')
      navigate(nextURL ?? '/')
    } catch (error: any) {
      if (error.data.message) {
        toast.error(error.data.message)
      }
    }
  }

  const handleKeyPress = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
      handleLogin()
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  return (
    <OnboardingWrapper className="signin">
      <OnboardingLeft className="signin">
        <OnboardingLogo>
          <img alt="Hupo" src="/logo-full-white.png" />
        </OnboardingLogo>
        <OnboardingStepper className="signin">
          <OnboardingStepperHeader className="signin">
            <h1> {t('signin')} </h1>
            <p className="signin">
              {t('signinSubtitle')
                .split('/n')
                .map((part, index) => (
                  <span key={`subTitle-${index}`}> {part} </span>
                ))}
            </p>
          </OnboardingStepperHeader>

          <Stack alignItems="center" gap="16px">
            <StyledTextField
              label="Email"
              placeholder="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              sx={{ minWidth: 280 }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={handleKeyPress}
            />
            <StyledTextField
              label="Password"
              placeholder="Password"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{ color: '#fff' }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyDown={handleKeyPress}
              sx={{ minWidth: 280, mt: 1 }}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <Box
              width={280}
              display="flex"
              justifyContent="right"
              sx={{ mt: '-12px', fontSize: 10 }}
            >
              <Link className="signinLink" to="/forgot-password">
                {t('forgot_password')}
              </Link>
            </Box>
            <Button
              variant="contained"
              onClick={handleLogin}
              disabled={loginLoading}
              fullWidth
              size="large"
              sx={{ mt: 1 }}
            >
              {loginLoading ? (
                <CircularProgress color="inherit" size="24px" />
              ) : (
                t('signin')
              )}
            </Button>
            <Divider sx={{ borderColor: '#f76f40', width: '98%' }} />
            <Button
              className="circular"
              sx={(theme) => ({
                minWidth: '280px',
                background: theme.palette.primary.contrastText,
                ':hover': {
                  background: theme.palette.primary.contrastText,
                },
              })}
              size="large"
              startIcon={<GoogleIcon />}
              onClick={() => googleLogin()}
              disabled={isLoading}
            >
              {t(isLoading ? 'syncing' : 'continueWithGoogle')}
            </Button>
            <Link className="signinLink" to="/onboarding">
              {t('register')}
            </Link>
            <input type="hidden" id="adminSecret" />
          </Stack>
        </OnboardingStepper>
      </OnboardingLeft>
    </OnboardingWrapper>
  )
}
