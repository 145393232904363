import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { PeerAssessmentItem } from '../../interfaces/main-interfaces'
import { PeerAssessmentUsersList } from './peer-assessment.style'
import { AccountCircle } from '@mui/icons-material'

export function PeerAssessmentUsers({
  questions,
}: {
  questions: PeerAssessmentItem[]
}) {
  return (
    <PeerAssessmentUsersList>
      {questions.map((question) => (
        <ListItemButton key={question.user}>
          <ListItemAvatar>
            <AccountCircle />
          </ListItemAvatar>
          <ListItemText
            primary={question.user}
            secondary={question.authorEmail}
          />
        </ListItemButton>
      ))}
    </PeerAssessmentUsersList>
  )
}
