/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import { useMediaQuery, useTheme } from '@mui/material'
import { Header } from './header/Header'
import { MainLayoutPage } from './main-layout.style'
import { Sidebar } from './sidebar/Sidebar'
import { useAuth } from '../../hooks/useAuth'
import { ChatProvider } from '../../app/providers/chat-provider/ChatProvider'
import { ChatUtilsProvider } from '../../app/providers/chat-utils/ChatUtils'
import { TimezonDialog } from '../../lib/widgets/timezone-dialog/TimezoneDialog'

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export function MainLayout() {
  const { user, checkUser } = useAuth()
  const [timezoneVisible, setTimezoneVisible] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isChat = location.pathname.includes('team-chat')
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down('lg'))

  useEffect(() => {
    checkUser()
  }, [])

  useEffect(() => {
    if (searchParams.has('nextURL')) {
      const nextURL = searchParams.get('nextURL')
      if (nextURL) {
        navigate(nextURL)
      }
    }
  }, [searchParams, navigate])

  useEffect(() => {
    if (user && user.user.timezone !== timeZone) {
      setTimezoneVisible(true)
    }

    if (user) {
      Sentry.setUser({
        id: user.user._id,
        email: user.user.email,
        username: user.user.profile?.name,
      })

      posthog.identify(user.user._id, {
        email: user.user.email,
        name: user.user.profile?.name,
        coach: user.user.coach?.email,
      })
    }
  }, [user])

  return user ? (
    <ChatProvider>
      <ChatUtilsProvider>
        <Header />
        <Sidebar />
        <MainLayoutPage className={isMd && isChat ? 'chatPage' : ''}>
          <Outlet />
        </MainLayoutPage>
        {timezoneVisible && (
          <TimezonDialog onClose={() => setTimezoneVisible(false)} />
        )}
      </ChatUtilsProvider>
    </ChatProvider>
  ) : (
    <Navigate
      to={`/login?nextURL=${location.pathname}?${searchParams.toString()}`}
    />
  )
}
