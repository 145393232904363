import { useTranslation } from 'react-i18next'
import { SessionStatsContent } from './SessionStatsContent'
import { useGetSessionStatsQuery } from '../../../app/store/services/manager.service'
import { useMemo } from 'react'

export function SessionStats() {
  const { t } = useTranslation()
  const { data: sessions } = useGetSessionStatsQuery(void 0, {
    refetchOnMountOrArgChange: true,
  })

  const sessionData = useMemo(() => {
    if (!sessions)
      return {
        team: { upcomingCount: 0, completedCount: 0, remainingCount: 0 },
        individual: { upcomingCount: 0, completedCount: 0, remainingCount: 0 },
        all: { upcomingCount: 0, completedCount: 0, remainingCount: 0 },
      }
    return {
      team: {
        upcomingCount: sessions.upcomingSessions.team,
        completedCount: sessions.completedSessions.team,
        remainingCount: sessions.remainingSessions.team,
      },
      individual: {
        upcomingCount: sessions.upcomingSessions.individual,
        completedCount: sessions.completedSessions.individual,
        remainingCount: sessions.remainingSessions.individual,
      },
      all: {
        upcomingCount:
          sessions.upcomingSessions.team + sessions.upcomingSessions.individual,
        completedCount:
          sessions.completedSessions.team +
          sessions.completedSessions.individual,
        remainingCount:
          sessions.remainingSessions.team +
          sessions.remainingSessions.individual,
      },
    }
  }, [sessions])

  const showSeparateStats = useMemo(() => {
    if (!sessions) return false
    const individualSessionOverallCount =
      sessions.upcomingSessions.individual +
      sessions.completedSessions.individual +
      sessions.remainingSessions.individual
    return individualSessionOverallCount > 0
  }, [sessions])

  return showSeparateStats ? (
    <>
      <SessionStatsContent
        showViewMore={false}
        sessionData={sessionData.team}
        mode="team"
      />
      <SessionStatsContent
        showViewMore={false}
        sessionData={sessionData.individual}
        mode="individual"
      />
    </>
  ) : (
    <SessionStatsContent
      showViewMore={false}
      sessionData={sessionData.all}
      mode="all"
    />
  )
}
