import { useTranslation } from 'react-i18next'
import { managerLinks, teamMemberLinks } from '../constants/navLinks'
import {
  ChatMessageCount,
  SidebarContent,
  SidebarLink,
  SidebarOverlay,
  SidebarWrapper,
} from './sidebar.style'
import { useAuth } from '../../../hooks/useAuth'
import { useChatUtils } from '../../../app/providers/chat-utils/useChatUtils'
import { useAppDispatch, useAppSelector } from '../../../app/store/store.config'
import { setSidebarVisible } from '../../../app/store/slices/uiSlice'
import { HeaderLogo } from '../main-layout.style'
import { IconButton, Stack, useMediaQuery, useTheme } from '@mui/material'
import { Close } from '@mui/icons-material'

export function Sidebar() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const isManager = user?.user.isManager
  const { chats } = useChatUtils()
  const unreadMessagesCount = chats?.unreadMessagesCount || 0
  const visible = useAppSelector((store) => store.ui.sidebarVisible)
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isMd = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <>
      <SidebarWrapper className={visible ? 'visible' : ''}>
        {isMd && (
          <Stack
            p="12px 12px 0 20px"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
          >
            <HeaderLogo to="/">
              <img src="/logo-full.png" alt="Hupo" />
            </HeaderLogo>
            <IconButton onClick={() => dispatch(setSidebarVisible(false))}>
              <Close />
            </IconButton>
          </Stack>
        )}
        <SidebarContent>
          {(isManager ? managerLinks : teamMemberLinks).map((link) => {
            const isChat = link.label === 'team-chat'

            return (
              <SidebarLink
                onClick={() => isMd && dispatch(setSidebarVisible(false))}
                key={link.to}
                to={link.to}
              >
                <span className="linkIcon">
                  <link.icon />
                </span>
                <p className="linkLabel">{t(link.label)}</p>

                {isChat && unreadMessagesCount > 0 && (
                  <ChatMessageCount>
                    {unreadMessagesCount < 100 ? unreadMessagesCount : '99+'}
                  </ChatMessageCount>
                )}
              </SidebarLink>
            )
          })}
        </SidebarContent>
      </SidebarWrapper>
      {isMd && visible && (
        <SidebarOverlay
          onClick={() => dispatch(setSidebarVisible(false))}
          className={visible ? 'visible' : ''}
        />
      )}
    </>
  )
}
