export const GOOGLE_CALENDAR_SCOPES = [
  'https://www.googleapis.com/auth/calendar.readonly',
  'https://www.googleapis.com/auth/calendar.events',
]

export const DEFAULT_TEAM_SESSION_DURATION = 90
export const DEFAULT_INDIVIDUAL_SESSION_DURATION = 45

export const firstSessionOptions = [
  'first_session_feedback_great_chemistry_with_coach',
  'first_session_feedback_clear_action_plan',
  'first_session_feedback_enjoyed_exploring_topics',
  'first_session_feedback_need_time_build_trust',
  'first_session_feedback_hope_more_structured_approach',
  'first_session_feedback_not_sure_how_best_harness_coaching',
  'other',
]

export const highOptions = [
  'five_star_feedback_discovered_smth_new_myself',
  'five_star_feedback_express_thoughts_and_supported',
  'five_star_feedback_gained_tools_for_development',
  'five_star_feedback_feel_continued_progress_towards_goals',
  'other',
]
export const midOptions = [
  'medium_rate_feedback_wants_session_more_structured',
  'medium_rate_feedback_wants_concrete_tools',
  'medium_rate_feedback_wants_continuity_sessions',
  'medium_rate_feedback_more_discussions_between_participants',
  'other',
]
export const lowOptions = [
  'low_rate_feedback_content_generic_unhelpful',
  'other',
]

export const CANCELLATION_POLICY_HOURS = 24
