import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Card,
  Divider,
  CircularProgress,
  TextField,
} from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { highOptions, lowOptions, midOptions } from '../../constants'
import { useTranslation } from 'react-i18next'
import { Logo } from '../hupohighlights/Logo'
import { usePostSessionUserFeedbackMutation } from '../../app/store/services/meeting.service'
import { RatingComponent } from 'react-rating-emoji'
import 'react-rating-emoji/dist/index.css'
import { toast } from 'sonner'
import { usePostHog } from 'posthog-js/react'

const REDIRECT_SECONDS = 3
const HOME_PAGE = `${window.location.protocol}//${window.location.host}`

const SelectOption = ({ title, value, selected, onItemSelected }: any) => {
  const isItemSelected = selected.has(value)
  return (
    <Card
      key={value}
      sx={{
        mb: '20px',
        mx: 'auto',
        borderRadius: 2,
        bgcolor: isItemSelected ? 'primary.main' : 'background.default',
        color: isItemSelected ? '#fff' : 'text.primary',
        cursor: 'pointer',
        p: '14px',
        '&:hover': {
          bgcolor: isItemSelected ? 'primary.dark' : 'grey.100',
        },
      }}
      onClick={() => onItemSelected(value)}
    >
      <Typography variant="body1" fontWeight="400" textAlign="center">
        {title}
      </Typography>
    </Card>
  )
}

interface PostSessionUserFeedbackProps {
  sessionId: string
  userId: string
  coachName: string
  source: string
  handlePopupClose?: () => void
  selectedRating?: string
}

const PostSessionUserFeedback: React.FC<PostSessionUserFeedbackProps> = ({
  sessionId,
  userId,
  coachName,
  handlePopupClose,
  selectedRating,
  source,
}) => {
  const { t } = useTranslation()
  const posthog = usePostHog()
  const [submitting, setSubmitting] = useState(false)
  const [rating, setRating] = useState<number>(0)
  const [answers, setAnswers] = useState<Set<string>>(new Set())
  const [otherText, setOtherText] = useState<string>('')
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [redirectionTimer, setRedirectionTimer] = useState(REDIRECT_SECONDS)
  const [postSessionUserFeedback] = usePostSessionUserFeedbackMutation()

  useEffect(() => {
    posthog.capture('post_session_feedback_displayed', {
      source,
    })
  }, [])

  const feedbackSentRef = useRef(false)
  useEffect(() => {
    const sendFeedback = async () => {
      if (feedbackSentRef.current) return
      feedbackSentRef.current = true
      if (selectedRating) {
        setRating(parseInt(selectedRating))
        onSetStarRating(parseInt(selectedRating))
      } else {
        //create default with null rating
        await postSessionUserFeedback({
          sessionId,
          userId,
        })
      }
    }

    sendFeedback()
  }, [postSessionUserFeedback, sessionId, userId, selectedRating])

  const isPostCall = source === 'post_call'

  const getFollowUpQuestionTitle = useCallback(
    (rating: number) => {
      if (rating === 1 || rating === 2) {
        return t('session_review_followup_title_rating_low')
      } else if (rating === 3 || rating === 4) {
        return t('session_review_followup_title_rating_mid')
      } else if (rating === 5) {
        return t('session_review_followup_title_rating_high')
      }
      return ''
    },
    [t],
  )

  const onSetStarRating = async (rating: number) => {
    setRating(rating)
    setAnswers(new Set())
    try {
      await postSessionUserFeedback({
        sessionId,
        userId,
        rating,
      }).unwrap()

      posthog.capture('post_session_feedback_submitted', {
        source,
        rating,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const optionsHandler = (rate: number) => {
    if (rate === 5) {
      return highOptions
    }
    if (rate === 3 || rate === 4) {
      return midOptions
    }
    if (rate === 1 || rate === 2) {
      return lowOptions
    }

    return []
  }

  const onSubmit = async () => {
    const answersArray = Array.from(answers)

    if (answersArray.length === 0 && !otherText) {
      toast.error('Invalid input')
      return
    }
    try {
      setSubmitting(true)
      await postSessionUserFeedback({
        sessionId,
        rating,
        answers: Array.from(answers),
        other: otherText,
        userId,
      }).unwrap()

      if (['email', 'post_call'].includes(source)) {
        setIsRedirecting(true)
        const timer = setInterval(() => {
          setRedirectionTimer((prevTimer) => {
            if (prevTimer > 0) {
              return prevTimer - 1
            }
            clearInterval(timer)
            return 0
          })
        }, 1000)
        setTimeout(() => {
          clearInterval(timer)
          window.location.href = HOME_PAGE
        }, REDIRECT_SECONDS * 1000)
      } else if (handlePopupClose) {
        handlePopupClose()
      }
    } catch (err) {
      toast.error('Something went wrong. Please try again later.')
    } finally {
      setSubmitting(false)
    }
  }

  const onItemSelected = async (item: string) => {
    const options = new Set(answers)
    if (options.has(item)) {
      options.delete(item)
    } else {
      options.add(item)
    }
    setAnswers(options)
    try {
      await postSessionUserFeedback({
        sessionId,
        rating,
        answers: Array.from(options),
        userId,
      })
    } catch (err) {
      console.log(err)
    }
  }

  if (isRedirecting) {
    return (
      <Box
        height={isPostCall ? '100vh' : 'auto'}
        bgcolor={isPostCall ? '#222' : '#fff'}
      >
        <Container maxWidth="md" sx={{ pt: 3, pb: 6 }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={4}
          >
            <Logo md={5} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="200px"
          >
            <Typography
              variant="h5"
              color={isPostCall ? '#FFF' : 'primary.main'}
              textAlign="center"
            >
              {t('thank_you_for_your_feedback', { redirectionTimer })}
            </Typography>
          </Box>
        </Container>
      </Box>
    )
  }

  const renderOption = (child: string) => {
    if (child === 'other') {
      return (
        <Box maxWidth={{ lg: isPostCall ? '60%' : '90%' }} mt={2}>
          <Typography variant="body2">
            {t('other_feedback_label_multiple_sessions')}
          </Typography>
          <TextField
            variant="outlined"
            placeholder={t('other_feedback')}
            multiline
            rows={3}
            fullWidth
            sx={{
              borderRadius: '12px',
              backgroundColor: isPostCall ? '#222' : '#fff',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
              },
              mt: 1,
            }}
            onChange={(e) => {
              setOtherText(e.target.value)
            }}
            InputProps={{
              style: {
                color: isPostCall ? '#fff' : '#000',
              },
            }}
          />
        </Box>
      )
    }

    return (
      <SelectOption
        title={t(`${child}`)}
        value={child}
        selected={answers}
        onItemSelected={onItemSelected}
      />
    )
  }

  return (
    <Box pt={3} pb={6}>
      <Container
        maxWidth="md"
        sx={{
          color: isPostCall ? '#fff' : 'text.primary',
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
          <Logo md={5} />
        </Box>

        {isPostCall && (
          <>
            <Typography variant="h5" fontWeight="600" mb={3} textAlign="center">
              {t('session_completed')} 🎉!
            </Typography>
            <Divider />
          </>
        )}

        <Typography variant="h6" my={3} textAlign="center">
          {isPostCall
            ? t('session_review_title', { coach: coachName })
            : t('previous_session_review_title', { coach: coachName })}
        </Typography>
        <Box display="flex" justifyContent="center" mb={4}>
          <RatingComponent rating={rating} onClick={onSetStarRating} />
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box>
              {rating >= 1 && rating <= 5 ? (
                <Box style={{ alignItems: 'center' }}>
                  <Typography
                    variant="h6"
                    fontSize="lg"
                    textAlign="center"
                    fontWeight={500}
                    mb={2}
                  >
                    {getFollowUpQuestionTitle(rating)}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {optionsHandler(rating).map((option) =>
                      renderOption(option),
                    )}
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Grid>
        </Grid>

        {answers.size === 0 && otherText === '' ? null : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={3}
          >
            {submitting ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="200px"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={submitting}
                onClick={onSubmit}
                sx={{
                  width: '180px',
                  height: '50px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                }}
              >
                {t('submit')}
              </Button>
            )}
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default PostSessionUserFeedback
