import { combineReducers } from '@reduxjs/toolkit'

import { authService } from './services/auth.service'
import { coachService } from './services/coach.service'
import { managerService } from './services/manager.service'
import { libraryService } from './services/library.service'
import authSlice from './slices/authSlice'
import uiSlice from './slices/uiSlice'
import npsSlice from './slices/npsSlice'
import socketSlice from './slices/socketSlice'
import { meetingService } from './services/meeting.service'
import { coreApi } from './services/core.service'
import { calendarService } from './services/calendar.service'
import { feedbackService } from './services/feedback.service'
import { npsService } from './services/nps.service'

export const reducer = combineReducers({
  ui: uiSlice.reducer,
  auth: authSlice.reducer,
  socket: socketSlice.reducer,
  nps: npsSlice.reducer,
  [authService.reducerPath]: authService.reducer,
  [coachService.reducerPath]: coachService.reducer,
  [managerService.reducerPath]: managerService.reducer,
  [libraryService.reducerPath]: libraryService.reducer,
  [meetingService.reducerPath]: meetingService.reducer,
  [coreApi.reducerPath]: coreApi.reducer,
  [calendarService.reducerPath]: calendarService.reducer,
  [feedbackService.reducerPath]: feedbackService.reducer,
  [npsService.reducerPath]: npsService.reducer,
})
