import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import { OnboardingContent } from '../onboardint-styles'
import { OnboardingHeader } from '../shared/OnboardingHeader'
import GoogleIcon from '../../../assets/icons/Google'
import { useAppDispatch } from '../../../app/store/store.config'
import { setUser } from '../../../app/store/slices/authSlice'
import {
  useGoogleAuthMutation,
  useSignupMutation,
} from '../../../app/store/services/auth.service'
import { toast } from 'sonner'
import { usePostHog } from 'posthog-js/react'
import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export function GoogleConnect() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const posthog = usePostHog()
  const [searchParams] = useSearchParams()
  const [googleAuth, { isLoading }] = useGoogleAuthMutation()
  const [signup, { isLoading: signupLoading }] = useSignupMutation()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async ({ code }) => {
      try {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const response = await googleAuth({ code, timeZone }).unwrap()
        dispatch(setUser(response))

        if (response.existingUser) {
          const nextURL = searchParams.get('nextURL')
          navigate(nextURL ?? '/')
        } else {
          navigate('/onboarding/connect-calendar')
        }

        posthog.capture('onboarding', {
          step: 'google_connect',
        })
      } catch (error: any) {
        if (error.data.message) {
          toast.error(error.data.message)
        }
      }
    },
    onError: (error) => {
      posthog.capture('onboarding_error', {
        step: 'google_connect',
        error: error.error_description,
      })
      if (error.error_description) {
        toast.error(error.error_description)
        console.log(error.error_uri)
      } else {
        toast.error(t('somethingWentWrong'))
      }
    },
  })

  const handleSignup = async () => {
    let valid = true
    if (!name) {
      setNameError(t('name_required'))
      valid = false
    }

    if (!email) {
      setEmailError(t('email_required'))
      valid = false
    }

    if (!password) {
      setPasswordError(t('password_required'))
      valid = false
    }

    if (!valid) return

    if (errors.length > 0) {
      return
    }

    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

      const response = await signup({
        name,
        email,
        password,
        timeZone,
      }).unwrap()
      dispatch(setUser(response))

      const nextURL = searchParams.get('nextURL')
      navigate(nextURL ?? '/')

      posthog.capture('onboarding', {
        step: 'signup',
      })
    } catch (error: any) {
      if (error.data.message) {
        toast.error(error.data.message)
      }
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  const handlePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    validatePassword(event.target.value)
    setPasswordError('')
  }

  const validatePassword = (password: string) => {
    const requirements = [
      { re: /.{10}/, message: t('password_requirements_length') },
      { re: /[A-Z]/, message: t('password_must_contain_uppercase_letter') },
      { re: /[a-z]/, message: t('password_must_contain_lowercase_letter') },
      {
        re: /^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/,
        message: t('password_must_contain_special_character'),
      },
    ]

    const errors: string[] = []

    for (const req of requirements) {
      if (!req.re.test(password)) {
        errors.push(req.message)
      }
    }
    setErrors(errors)
  }

  return (
    <OnboardingContent>
      <Stack gap="24px" alignItems="center">
        <OnboardingHeader
          title={t('completeProfile')}
          subTitle={t('completeProfileSubtitle')}
        />
        <Stack alignItems="center" gap="16px">
          <TextField
            label="Name"
            color="primary"
            placeholder="Name"
            variant="outlined"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
              setNameError('')
            }}
            fullWidth
            sx={{ minWidth: 280 }}
            InputLabelProps={{ shrink: true }}
            error={!!nameError}
            helperText={nameError}
          />
          <TextField
            label="Email"
            color="primary"
            placeholder="Email"
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              setEmailError('')
            }}
            fullWidth
            sx={{ minWidth: 280, mt: 1 }}
            InputLabelProps={{ shrink: true }}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            label="Password"
            placeholder="Password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ color: '#888' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 280, mt: 1 }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={!!passwordError}
            helperText={passwordError}
          />

          {password && errors.length > 0 ? (
            <Box
              borderRadius={1}
              px={2}
              py={1}
              sx={{ width: 280 }}
              bgcolor="#FDEDED"
            >
              {errors.map((error) => (
                <Typography
                  variant="body2"
                  alignContent={'center'}
                  sx={{ my: '4px' }}
                  color="#5F2220"
                >
                  <ErrorOutlineIcon
                    sx={{ mr: 1, width: 24, color: '#D74342' }}
                  />{' '}
                  {error}
                </Typography>
              ))}
            </Box>
          ) : null}

          <Button
            variant="contained"
            onClick={handleSignup}
            disabled={signupLoading}
            fullWidth
            size="large"
            sx={{ mt: 1 }}
          >
            {t('register')}
          </Button>
          <Divider sx={{ color: '#ECECEC', width: '98%' }} />
          <Button
            variant="outlined"
            className="circular"
            sx={{ minWidth: '260px' }}
            size="large"
            startIcon={<GoogleIcon />}
            onClick={() => googleLogin()}
            disabled={isLoading}
            fullWidth
          >
            {t(isLoading ? 'syncing' : 'continueWithGoogle')}
          </Button>
        </Stack>
      </Stack>
    </OnboardingContent>
  )
}
