import { styled } from '@mui/material'

export const LQWrapper = styled('div')(() => ({
  display: 'grid',
  gap: '12px',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
}))

export const LQItem = styled('div')(({ theme }) => ({
  background: theme.palette.grey[200],
  padding: '16px 24px',
  borderRadius: '12px',
  cursor: 'pointer',
  textAlign: 'center',
  userSelect: 'none',
  font: '500 16px Poppins, sans-serif',
  '&.disabled': {
    cursor: 'default',
    userSelect: 'auto',
  },
}))

export const VSWrapper = styled('div')(() => ({
  display: 'grid',
  gap: '6px',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
}))

export const VSItem = styled('div')(({ theme }) => ({
  background: theme.palette.grey[200],
  padding: '16px 24px',
  borderRadius: '12px',
  cursor: 'pointer',
  textAlign: 'center',
  userSelect: 'none',
  font: '500 16px Poppins, sans-serif',
  '&.active': {
    color: theme.palette.primary.main,
    background: theme.palette.primary.light,
  },
  ':active': {
    transform: 'translateY(3px)',
  },
  [theme.breakpoints.down('md')]: {
    font: '500 12px Poppins, sans-serif',
    padding: '8px 12px',
  },
}))
