import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../app/store/store.config'
import { useCallback } from 'react'
import { setAssessmentResult } from '../../../app/store/slices/uiSlice'

export function TextAreaQuestion({
  placeholder,
  name,
  minWordLimit,
}: {
  placeholder: string
  name: string
  minWordLimit: number
}) {
  const { t } = useTranslation()
  const results = useAppSelector((store) => store.ui.assessmentResults)

  const dispatch = useAppDispatch()

  const handleChange = useCallback(
    (value: string) => {
      dispatch(
        setAssessmentResult({
          ...results,
          answers: {
            ...results.answers,
            [name]: value,
          },
        }),
      )
    },
    [dispatch, name, results],
  )
  return (
    <TextField
      placeholder={placeholder || t('typeHere')}
      multiline
      autoFocus
      minRows={7}
      variant="filled"
      style={{
        width: '100%',
      }}
      value={results.answers[name]}
      onChange={(event) => handleChange(event.target.value)}
      InputProps={{
        disableUnderline: true,
        inputProps: {
          style: {
            padding: '0 12px',
          },
        },
      }}
    />
  )
}
