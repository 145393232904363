import {
  DyteAudioVisualizer,
  DyteNameTag,
  DyteScreenshareView,
} from '@dytesdk/react-ui-kit'
import { useDyteMeeting } from '@dytesdk/react-web-core'

function ScreenShareView({ participant }: { participant: any }) {
  const { meeting } = useDyteMeeting()

  return (
    <DyteScreenshareView
      meeting={meeting}
      participant={participant}
      style={{ width: '80%' }}
      hideFullScreenButton={false}
    >
      <DyteNameTag participant={participant} meeting={meeting} isScreenShare>
        <DyteAudioVisualizer
          participant={participant}
          isScreenShare
          slot="start"
        />
      </DyteNameTag>
    </DyteScreenshareView>
  )
}

export default ScreenShareView
