import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useGetHighlightedAreasQuery } from '../../app/store/services/manager.service'
import { DashboardCardTitle } from '../../lib/shared/dashboard-card/dashboard-card.style'
import { TeamMembersTable } from './team-progress-status.style'

export function HighlightedAreas({ top }: { top: boolean }) {
  const { t } = useTranslation()
  const { data: highlightedAreas } = useGetHighlightedAreasQuery({
    type: top ? 'highest' : 'lowest',
  })

  return (
    <Box
      sx={{
        filter: 'var(--card-shadow)',
        padding: '22px',
        background: '#ffffff',
        borderRadius: '12px',
      }}
    >
      <DashboardCardTitle style={{ marginBottom: 16 }}>
        {top ? t('areasForCelebration') : t('areasForGrowth')}
      </DashboardCardTitle>
      <TeamMembersTable>
        <thead>
          <tr>
            <th> {t('teamBehaviour')} </th>
            <th> {t('averageRating')} </th>
          </tr>
        </thead>
        <tbody>
          {highlightedAreas?.map(
            (
              area: { description: string; score: number },
              index: number,
            ): any => (
              <tr key={`member_${index}`}>
                <td width="80%">
                  <div
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: 1,
                    }}
                  >
                    <span style={{ paddingBottom: 0, marginBottom: 0 }}>
                      {area.description}
                    </span>
                  </div>
                </td>
                <td width="20%">
                  <div style={{ paddingRight: 10 }}>
                    {Math.round(area.score * 100) / 100}
                  </div>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </TeamMembersTable>
    </Box>
  )
}
